import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => `${theme.grid.gutter / 2}px`};
  padding-right: ${({ theme }) => `${theme.grid.gutter / 2}px`};
  width: 100%;
  font-family: 'Montserrat'
    ${({
      theme: {
        grid: { navbarMaxWidths },
      },
    }) =>
      Object.keys(navbarMaxWidths).map((key) => {
        return css`
          ${breakpoint(key)`
          max-width: ${navbarMaxWidths[key]};
        `}
        `;
      })};
`;

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      display: none;

      ${breakpoint(theme.navbar.breakpoint)`
      ${css`
        display: block;
        background-color: #fff;
      `}
    `}
    `}
`;

export const Top = styled.div`
  height: 104px;
  border-bottom: 1px solid #d2d5d9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  > div {
    &:nth-of-type(1) {
      flex-grow: 0;
    }

    &:nth-of-type(2) {
      flex-grow: 1;
      //  max-width: 580px;
    }

    &:nth-of-type(3) {
      flex-grow: 1;
      max-width: 150px;
    }
  }
`;

export const Middle = styled.div`
  background-color: rgba(243, 243, 243, 1);
  height: 67px;
`;

export const Bottom = styled.div`
  height: 48px;
`;

export const RowStyled = styled(Grid)`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  ${({ wide }) =>
    wide &&
    css`
      flex: 1;
    `}
`;

export const Content = styled.div`
  position: absolute;
  top: 60px;
  width: 150px;
  transform: translate3d(0, 16px, 0);
  transition: transform 0.2s ease, opacity 0.2s ease;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(79, 78, 92, 0.32);
  z-index: ${({ theme }) => theme.zindex.menu};
`;

export const Col = styled.div`
  font-size: 16px;
  ${css`
    @media (max-width: 1600px) {
      font-size: 12px;
    }
  `}
`;

export const Row = styled.div`
  display: flex;
  ${({ wide }) =>
    wide &&
    css`
      flex: 1;
    `}
`;
