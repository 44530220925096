import {
  SET_REGISTRATION_USER_DATA,
  GET_REGISTRATION_USER_DATA,
  REGISTER_USER,
  LOAD_REGISTRATION_CERTS_FILTERS,
  SET_REGISTER_ERRORS,
  LOGIN_USER,
  SET_LOGIN_USER_LOADING,
  SET_LOGIN_USER_DATA,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_NEW_ALERT,
  REMOVE_ALERT,
  SET_USER,
  CHECK_TOKEN,
  EDIT_PROFILE,
  SET_EDIT_PROFILE_LOADING,
  SET_EDIT_PROFILE_ERRORS,
  SET_GLOBAL_COUNTRIES,
  SET_GLOBAL_AVAILABLE_MA,
  SET_GLOBAL_JOBS_TITLES,
  LOAD_GLOBAL_JOBS_TITLES,
  LOAD_GLOBAL_AVAILABLE_MA,
  LOAD_GLOBAL_COUNTRIES,
  LOAD_GLOBAL_PROFILE_PRIVACY,
  SET_GLOBAL_PROFILE_PRIVACY,
  LOAD_GLOBAL_GENDER,
  SET_GLOBAL_GENDER,
  LOAD_PROFILE,
  SET_PROFILE,
  SET_EDIT_PASSWORD_LOADING,
  SET_EDIT_PASSWORD_ERRORS,
  EDIT_PASSWORD,
  VALIDATE_REGISTRATION_USER_DATA,
  RESEND_REIGSTRATION_ACTIVATE,
  SHOW_ACTIVATION_ALERT,
  DELETE_PROFILE,
  SET_DELETE_PROFILE_ERRORS,
  SET_DELETE_PROFILE_LOADING,
  CREATE_ORGANISATION,
  SET_CREATE_ORGANISATION_LOADING,
  SET_CREATE_ORGANISATION_ERRORS,
  SET_APP_TYPE,
  LOAD_USER_ORGANISATION,
  SET_USER_ORGANISATION,
  LOGOUT_USER,
  SET_NEW_LOADING,
  REMOVE_LOADING,
  UPDATE_USER_ORGANISATION,
  LOAD_USER_ORGANISATION_PEOPLE,
  SET_USER_ORGANISATION_PEOPLE,
  SET_USER_ORGANISATION_PEOPLE_PAGES,
  SET_USER_ORGANISATION_PEOPLE_PAGE,
  SET_USER_ORGANISATION_PEOPLE_PER_PAGE,
  LOAD_USER_ORGANISATION_PEOPLE_ADD,
  SET_USER_ORGANISATION_PEOPLE_ADD,
  ADD_USER_ORGANISATION_PEOPLE_ADD,
  REMOVE_USER_ORGANISATION_PEOPLE,
  LOAD_GLOBAL_COURSE_DELIVERY_MODES,
  SET_GLOBAL_COURSE_DELIVERY_MODES,
  LOAD_GLOBAL_COURSE_COURSE_TYPES,
  SET_GLOBAL_COURSE_COURSE_TYPES,
  LOAD_GLOBAL_COURSE_INSTRUCTION_FORMATS,
  SET_GLOBAL_COURSE_INSTRUCTION_FORMATS,
  LOAD_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES,
  SET_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES,
  LOAD_GLOBAL_COURSE_PEOPLE_COMPETENCIES,
  SET_GLOBAL_COURSE_PEOPLE_COMPETENCIES,
  LOAD_GLOBAL_COURSE_PRACTICE_COMPETENCIES,
  SET_GLOBAL_COURSE_PRACTICE_COMPETENCIES,
  LOAD_GLOBAL_COURSE_CLOUD_TAGS,
  SET_GLOBAL_COURSE_CLOUD_TAGS,
  LOAD_GLOBAL_LANGUAGES,
  SET_GLOBAL_LANGUAGES,
  LOAD_GLOBAL_COURSE_ATTRIBUTES,
  CREATE_COURSE,
  SET_CREATE_COURSE_ERRORS,
  LOAD_MA_PEOPLE_BAR,
  SET_MA_PEOPLE_BAR,
  LOAD_MA_PEOPLE_USERS,
  SET_MA_PEOPLE_USERS,
  LOAD_GLOBAL_MA_ROLES,
  SET_GLOBAL_MA_ROLES,
  SET_MA_PEOPLE_USERS_SORT,
  CHANGE_MA_PEOPLE_USERS_ROLE,
  DELETE_MA_PEOPLE_USER,
  SET_MA_PEOPLE_USERS_PAGE,
  LOAD_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
  LOAD_USER_CERTIFICATIONS,
  SET_USER_CERTIFICATIONS,
  SET_MA_PEOPLE_USERS_PER_PAGE,
  LOAD_MA_PEOPLE_LIST,
  SET_MA_PEOPLE_LIST,
  SET_MA_PEOPLE_LIST_SORT,
  CHANGE_MA_PEOPLE_LIST_ROLE,
  SET_MA_PEOPLE_LIST_PAGE,
  SET_MA_PEOPLE_LIST_PER_PAGE,
  ACCEPT_MA_PEOPLE_LIST_USER,
  DECLINE_MA_PEOPLE_LIST_USER,
  LOAD_MA_PEOPLE_ADD_LIST_USERS,
  SET_MA_PEOPLE_ADD_LIST_USERS,
  ADD_MA_PEOPLE_USERS,
  LOAD_USER_ADD_LIST_CERTIFICATES,
  SET_USER_ADD_LIST_CERTIFICATES,
  SET_USER_ADD_LIST_FILTRATION,
  SET_USER_ADD_LIST_SELECTED_CERTIFICATE,
  ADD_USER_ADD_LIST_CERTIFICATES,
  LOAD_MA_PROFILE,
  SET_MA_PROFILE,
  UPDATE_MA_PROFILE,
  SET_UPDATE_MA_PROFILE_ERRORS,
  SET_MA_PROFILE_USERS_ROLE,
  LOAD_MA_DASHBOARD,
  SET_MA_DASHBOARD,
  LOAD_GLOBAL_CURRENCIES,
  SET_GLOBAL_CURRENCIES,
  LOAD_GLOBAL_EVENT_DELIVERY_MODE,
  SET_GLOBAL_EVENT_DELIVERY_MODE,
  LOAD_GLOBAL_EVENT_ATTRIBUTES,
  CREATE_EVENT,
  LOAD_LIST,
  SET_LIST,
  SET_LIST_PAGE,
  SET_LIST_PAGES,
  SET_LIST_PER_PAGE,
  SET_LIST_SORT,
  SET_LIST_FILTERS,
  REMOVE_LIST_FILTERS,
  LOAD_LIST_VIEW,
  SET_LIST_VIEW,
  REMOVE_LIST_VIEW,
  SET_GLOBAL_TIMEZONES,
  LOAD_GLOBAL_TIMEZONES,
  SET_MA_EVENTS_PAGE,
  SET_MA_EVENTS_PER_PAGE,
  LOAD_MA_EVENTS_BAR,
  SET_MA_EVENTS_BAR,
  LOAD_MA_EVENTS,
  SET_MA_EVENTS,
  SET_MA_EVENTS_STATUS,
  CANCEL_MA_EVENT,
  REOPEN_MA_EVENT,
  LOAD_MA_EVENT,
  EDIT_MA_EVENT,
  SET_CHECKED_TOKEN,
  APPLY_USER_TO_MA,
  SET_ORGANISATION_PERSON_RESPONSIBLE,
  UPDATE_ORGANISATION_PERSON_RESPONSIBLE,
  LOAD_PAGE_HOME,
  SET_PAGE_HOME,
  SET_MA_PEOPLE_ORGANISATIONS_PAGE,
  SET_MA_PEOPLE_ORGANISATIONS_PER_PAGE,
  LOAD_MA_PEOPLE_ORGANISATIONS,
  SET_MA_PEOPLE_ORGANISATIONS_SORT,
  SET_MA_PEOPLE_ORGANISATIONS,
  REMOVE_MA_PEOPLE_LIST_USER,
  LOAD_SEARCH,
  SET_SEARCH,
  SET_SEARCH_PAGES,
  SET_SEARCH_PAGE,
  SET_SEARCH_TOTALS,
  LOAD_SEARCH_TOTALS,
  SET_SEARCH_PER_PAGE,
  LOAD_USER_DASHBOARD,
  SET_USER_DASHBOARD,
  LOAD_PAGE_USER_PROFILE,
  SET_PAGE_USER_PROFILE,
  UPDATE_USER_EMAIL,
  APPLY_USER_TO_COURSE,
  SET_USER_PERMISSIONS,
  SET_USER_MEMBER_ASSOCIATION_PEOPLE,
  LOAD_ORGANISATION_DASHBOARD,
  SET_ORGANISATION_DASHBOARD,
  SET_PROFILE_COMPLETED_COURSES,
  LOAD_PROFILE_COMPLETED_COURSES,
  LOAD_MA_PROFILE_UPCOMING_EVENTS,
  SET_MA_PROFILE_UPCOMING_EVENTS,
  LOAD_ORGANISATION_COURSE_COMPLETION,
  SET_ORGANISATION_COURSE_COMPLETION,
  SET_ORGANISATION_COURSE_COMPLETION_USERS,
  SET_ORGANISATION_COURSE_COMPLETION_USER_STATUS,
  SET_ORGANISATION_COURSE_COMPLETION_SORT,
  SUBMIT_PAGE_CONTACT_FORM,
  LOAD_GLOBAL_CONTACT_SUBJECTS,
  SET_GLOBAL_CONTACT_SUBJECTS,
  LOAD_MA_COURSES,
  SET_MA_COURSES,
  SET_MA_COURSES_PAGES,
  SET_MA_COURSES_PAGE,
  SET_MA_COURSES_PER_PAGE,
  LOAD_MA_COURSES_BAR,
  SET_MA_COURSES_BAR,
  LOAD_MA_ASSESSORS,
  SET_MA_ASSESSORS,
  CHANGE_MA_COURSES_ASSESSOR,
  LOAD_USER_COURSES,
  SET_USER_COURSES,
  SET_USER_COURSES_PAGES,
  SET_USER_COURSES_PAGE,
  SET_USER_COURSES_PER_PAGE,
  LOAD_USER_COURSES_BAR,
  SET_USER_COURSES_BAR,
  LOAD_ORGANISATION_DASHBOARD_COURSES,
  SET_ORGANISATION_DASHBOARD_COURSES,
  LOAD_ORGANISATION_PROFILE_COURSES,
  SET_ORGANISATION_PROFILE_COURSES,
  LOAD_ORGANISATION_COURSES,
  SET_ORGANISATION_COURSES,
  SET_ORGANISATION_COURSES_PAGES,
  SET_ORGANISATION_COURSES_PAGE,
  SET_ORGANISATION_COURSES_PER_PAGE,
  LOAD_ORGANISATION_COURSES_BAR,
  SET_ORGANISATION_COURSES_BAR,
  ACCEPT_MA_COURSE,
  LOAD_ORGANISATION_COURSE_PUBLISH,
  APPLY_ORGANISATION_COURSE_PUBLISH,
  DECLINE_USER_FROM_COURSE,
  LOAD_SUBCOURSE_PARENT,
  CREATE_SUBCOURSE,
  LOAD_HOME_SLIDER,
  SET_HOME_SLIDER,
  UPDATE_COURSE_DRAFT,
  UPDATE_SUBMITTED_TEMPLATE_COURSE,
  CANCEL_ORGANISATION_COURSE,
  RESET_APP,
  REMOVE_USER_CERTIFICATIONS,
  APP_ADD_TO_NEWSLETTER,
  LOAD_USER_BADGES,
  SET_USER_BADGES,
  CLAIM_USER_BADGE,
  LOAD_PAGE_USER_PROFILE_BADGES,
  SET_PAGE_USER_PROFILE_BADGES,
  LOAD_GLOBAL_INDUSTRIES,
  SET_GLOBAL_INDUSTRIES,
  CANCEL_MA_COURSE,
  LOAD_GLOBAL_ORGANISATION_ROLES,
  SET_GLOBAL_ORGANISATION_ROLES,
  CHANGE_ORGANISATION_USER_ROLE,
  CONNECT_AZURE,
  LOAD_GLOBAL_PROFESSIONAL_TITLES,
  SET_GLOBAL_PROFESSIONAL_TITLES,
  LOAD_MA_PEOPLE_MEMBERS,
  SET_MA_PEOPLE_MEMBERS,
  REMOVE_MA_PEOPLE_MEMBERS_USER,
  DUPLICATE_MA_EVENT,
  SOCIAL_LOGIN,
  SOCIAL_AUTH,
  SET_IPMA_DASHBOARD,
  LOAD_IPMA_DASHBOARD,
  SET_IPMA_PROFILE,
  LOAD_IPMA_PROFILE,
  SET_IPMA_PROFILE_USERS_ROLE,
  LOAD_IPMA_PROFILE_UPCOMING_EVENTS,
  SET_IPMA_PROFILE_UPCOMING_EVENTS,
  SET_UPDATE_IPMA_PROFILE_ERRORS,
  UPDATE_IPMA_PROFILE,
  LOAD_IPMA_PEOPLE_BAR,
  SET_IPMA_PEOPLE_BAR,
  LOAD_IPMA_PEOPLE_USERS,
  SET_IPMA_PEOPLE_USERS,
  SET_IPMA_PEOPLE_USERS_SORT,
  CHANGE_IPMA_PEOPLE_USERS_ROLE,
  DELETE_IPMA_PEOPLE_USER,
  SET_IPMA_PEOPLE_USERS_PAGE,
  SET_IPMA_PEOPLE_USERS_PER_PAGE,
  LOAD_GLOBAL_IPMA_ROLES,
  SET_GLOBAL_IPMA_ROLES,
  LOAD_IPMA_PEOPLE_ADD_LIST_USERS,
  SET_IPMA_PEOPLE_ADD_LIST_USERS,
  ADD_IPMA_PEOPLE_USERS,
  LOAD_IPMA_PEOPLE_LIST,
  LOAD_IPMA_EVENTS,
  SET_IPMA_EVENTS,
  LOAD_IPMA_EVENTS_BAR,
  SET_IPMA_EVENTS_BAR,
  SET_IPMA_EVENTS_PAGE,
  SET_IPMA_EVENTS_PER_PAGE,
  SET_IPMA_EVENTS_STATUS,
  CANCEL_IPMA_EVENT,
  DUPLICATE_IPMA_EVENT,
  REOPEN_IPMA_EVENT,
  CREATE_IPMA_EVENT,
  LOAD_IPMA_EVENT,
  EDIT_IPMA_EVENT,
  IPMA_SHOP_SSO,
  LOAD_IPMA_EVENT_PARTICIPANTS,
  SET_IPMA_EVENT_PARTICIPANTS,
  SET_IPMA_EVENT_PARTICIPANTS_PAGE,
  SET_IPMA_EVENT_PARTICIPANTS_PER_PAGE,
  UPLOAD_IPMA_EVENT_PARTICIPANTS,
  SET_OPEN_PENDING_BADGES,
  SET_OPEN_PENDING_COUPON,
  CLAIM_EVENT_PENDING_BADGE,
  REJECT_EVENT_PENDING_BADGE,
  SET_IPMA_AWARD_PARTICIPANTS,
  LOAD_IPMA_AWARD_PARTICIPANTS,
  SET_IPMA_AWARD_PARTICIPANTS_PAGE,
  SET_IPMA_AWARD_PARTICIPANTS_PER_PAGE,
  UPLOAD_IPMA_AWARD_PARTICIPANTS,
  CLAIM_PENDING_AWARD,
  REJECT_PENDING_AWARD,
  SET_IPMA_AWARDS_LIST,
  SET_IPMA_AWARDS_LIST_PAGE,
  SET_IPMA_AWARDS_LIST_PER_PAGE,
  LOAD_IPMA_AWARDS_LIST,
  LOAD_IPMA_REG_OFFICE_COURSES,
  SET_IPMA_REG_OFFICE_COURSES,
  LOAD_IPMA_REG_OFFICE_COURSES_BAR,
  SET_IPMA_REG_OFFICE_COURSES_BAR,
  SET_IPMA_REG_OFFICE_COURSES_PAGE,
  SET_IPMA_REG_OFFICE_COURSES_PER_PAGE,
  SET_IPMA_REG_OFFICE_COURSES_STATUS,
  ACCEPT_IPMA_REG_OFFICE_COURSE,
  REJECT_IPMA_REG_OFFICE_COURSE,
  UPLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  LOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  SET_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  REMOVE_IPMA_REG_OFFICE_COURSE_REG_LOGO,
  DOWNLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGO,
  LOAD_IPMA_REG_OFFICE_STAT_TABLE,
  SET_IPMA_REG_OFFICE_STAT_TABLE,
  LOAD_IPMA_REG_OFFICE_GOOGLE_LINK,
  SET_IPMA_REG_OFFICE_GOOGLE_LINK,
  TOGGLE_REG_OFFICE_MA_TYPE,
  TOGGLE_REG_OFFICE_ORGANIZATION_FR,
  SEND_MA_REPORT,
  SET_MA_REPORT_ERRORS,
  DOWNLOAD_MA_PEOPLE_REPORT,
  LOAD_MA_REPORT_LIST,
  SET_MA_REPORT_LIST,
  LOAD_MA_REPORT,
  SET_MA_REPORT,
  LOAD_MA_LAST_REPORT,
  LOAD_IPMA_REPORT_LIST,
  SET_IPMA_REPORT_LIST,
  TOGGLE_REPORT_INVOICE,
  ARCHIVE_REPORT,
  SET_COURSE_BADGE_LIST,
  LOAD_COURSE_BADGE_LIST,
  CLAIM_COURSE_BADGE,
  REJECT_COURSE_BADGE,
  SUPPRESS_COURSE_BADGE,
  SUPPRESS_COUPON,
  CIS_REDIRECT,
  VERIFY_REPORT,
  LOAD_MA_PEOPLE_YC,
  SET_MA_PEOPLE_YC,
  SET_MA_PEOPLE_YC_SORT,
  SET_MA_PEOPLE_YC_PAGE,
  SET_MA_PEOPLE_YC_PER_PAGE,
  LOAD_USER_PENDING_BADGES,
  SET_USER_PENDING_BADGES,
  CLAIM_ASSESSOR_BADGE,
  REJECT_ASSESSOR_BADGE,
  SET_MA_PEOPLE_MEMBERS_PAGE,
  SET_MA_PEOPLE_MEMBERS_PER_PAGE,
  SET_MA_PEOPLE_MEMBERS_SORT,
  CLAIM_HONORARY_FELLOW_BADGE,
  REJECT_HONORARY_FELLOW_BADGE,
  DOWNLOAD_COURSE_DIPLOMA,
  EXPORT_REPORT,
  UPDATE_REPORT,
  SET_GLOBAL_YC_ROLES,
  LOAD_GLOBAL_YC_ROLES,
  SET_YC_DASHBOARD,
  LOAD_YC_PROFILE,
  SET_YC_PROFILE,
  UPDATE_YC_PROFILE,
  SET_UPDATE_YC_PROFILE_ERRORS,
  SET_YC_PROFILE_USERS_ROLE,
  SET_YC_PROFILE_UPCOMING_EVENTS,
  LOAD_YC_PROFILE_UPCOMING_EVENTS,
  LOAD_YC_DASHBOARD,
  SET_YC_PEOPLE_BAR,
  LOAD_YC_PEOPLE_BAR,
  SET_YC_PEOPLE_USERS,
  LOAD_YC_PEOPLE_USERS,
  SET_YC_PEOPLE_USERS_SORT,
  SET_YC_PEOPLE_USERS_PAGE,
  SET_YC_PEOPLE_USERS_PER_PAGE,
  SET_YC_PEOPLE_LIST,
  SET_YC_PEOPLE_LIST_SORT,
  SET_YC_PEOPLE_LIST_PAGE,
  SET_YC_PEOPLE_LIST_PER_PAGE,
  SET_YC_PEOPLE_ADD_LIST_USERS,
  LOAD_YC_PEOPLE_LIST,
  LOAD_YC_MEMBERS_LIST,
  CHANGE_YC_PEOPLE_USERS_ROLE,
  DELETE_YC_PEOPLE_USER,
  LOAD_YC_PEOPLE_ADD_LIST_USERS,
  ADD_YC_PEOPLE_USERS,
  ACCEPT_YC_PEOPLE_LIST_USER,
  DECLINE_YC_PEOPLE_LIST_USER,
  REMOVE_YC_PEOPLE_LIST_USER,
  DOWNLOAD_YC_PEOPLE_REPORT,
  LOAD_GLOBAL_AVAILABLE_YC,
  SET_GLOBAL_AVAILABLE_YC,
  LOAD_YC_EVENTS,
  SET_YC_EVENTS,
  LOAD_YC_EVENTS_BAR,
  SET_YC_EVENTS_BAR,
  SET_YC_EVENTS_PAGE,
  SET_YC_EVENTS_PER_PAGE,
  SET_YC_EVENTS_STATUS,
  CANCEL_YC_EVENT,
  DUPLICATE_YC_EVENT,
  REOPEN_YC_EVENT,
  CREATE_YC_EVENT,
  LOAD_YC_EVENT,
  EDIT_YC_EVENT,
  SET_MA_PEOPLE_LIST_FILTERS,
  LOAD_MA_PEOPLE_LIST_FILTERS,
  SET_MA_PEOPLE_MEMBERS_FILTERS,
  LOAD_MA_PEOPLE_MEMBERS_FILTERS,
  SET_MA_PEOPLE_YC_FILTERS,
  LOAD_MA_PEOPLE_YC_FILTERS,
  SET_MA_PEOPLE_USERS_FILTERS,
  LOAD_MA_PEOPLE_USERS_FILTERS,
  SET_IPMA_REPORTS_FILTERS,
  LOAD_IPMA_REPORTS_FILTERS,
  SET_MA_UPDATE_REPORT,
  CREATE_PROJECT,
  LOAD_YC_PROJECTS,
  SET_YC_PROJECTS,
  LOAD_YC_PROJECTS_BAR,
  SET_YC_PROJECTS_BAR,
  SET_YC_PROJECTS_PAGE,
  SET_YC_PROJECTS_PER_PAGE,
  SET_YC_PROJECTS_STATUS,
  LOAD_YC_PROJECT,
  SET_YC_PROJECT,
  EDIT_YC_PROJECT,
  LOAD_GLOBAL_PROJECT_CAREERLEVEL,
  LOAD_GLOBAL_PROJECT_SKILLS,
  LOAD_GLOBAL_PROJECT_ROLES,
  SET_GLOBAL_PROJECT_CAREERLEVEL,
  SET_GLOBAL_PROJECT_SKILLS,
  SET_GLOBAL_PROJECT_ROLES,
  CREATE_PROJECT_ROLE,
  LOAD_YC_PROJECT_ROLES,
  SET_YC_PROJECT_ROLES,
  LOAD_YC_PROJECT_ROLES_BAR,
  SET_YC_PROJECT_ROLES_BAR,
  SET_YC_PROJECT_ROLES_PAGE,
  SET_YC_PROJECT_ROLES_PER_PAGE,
  SET_YC_PROJECT_ROLES_STATUS,
  EDIT_YC_PROJECT_ROLE,
  LOAD_YC_PROJECT_ROLE,
  SET_YC_PROJECT_ROLE,
  CREATE_PROJECT_ROLE_APPLICATION,
  LOAD_YC_PROJECT_ROLE_APPLICATION,
  SET_YC_PROJECT_ROLE_APPLICATION,
  LOAD_YC_PROJECT_ROLE_APPLICATIONS,
  SET_YC_PROJECT_ROLE_APPLICATIONS,
  LOAD_YC_PROJECT_ROLE_APPLICATION_BAR,
  SET_YC_PROJECT_ROLE_APPLICATION_BAR,
  SET_YC_PROJECT_ROLE_APPLICATION_PAGE,
  SET_YC_PROJECT_ROLE_APPLICATION_PER_PAGE,
  SET_YC_PROJECT_ROLE_APPLICATION_STATUS,
  ACCEPT_YC_PROJECT_ROLE_APPLICATION,
  DECLINE_YC_PROJECT_ROLE_APPLICATION,
  PUT_ON_HOLD_YC_PROJECT_ROLE_APPLICATION,
  CREATE_IPMA_PROJECT,
  LOAD_IPMA_PROJECTS,
  SET_IPMA_PROJECTS,
  LOAD_IPMA_PROJECTS_BAR,
  SET_IPMA_PROJECTS_BAR,
  SET_IPMA_PROJECTS_PAGE,
  SET_IPMA_PROJECTS_PER_PAGE,
  SET_IPMA_PROJECTS_STATUS,
  EDIT_IPMA_PROJECT,
  LOAD_IPMA_PROJECT,
  SET_IPMA_PROJECT,
  LOAD_IPMA_PROJECT_ROLE,
  SET_IPMA_PROJECT_ROLE,
  CREATE_IPMA_PROJECT_ROLE,
  EDIT_IPMA_PROJECT_ROLE,
  LOAD_IPMA_PROJECT_ROLES,
  SET_IPMA_PROJECT_ROLES,
  LOAD_IPMA_PROJECT_ROLES_BAR,
  SET_IPMA_PROJECT_ROLES_BAR,
  SET_IPMA_PROJECT_ROLES_PAGE,
  SET_IPMA_PROJECT_ROLES_PER_PAGE,
  SET_IPMA_PROJECT_ROLES_STATUS,
  CREATE_IPMA_PROJECT_ROLE_APPLICATION,
  LOAD_IPMA_PROJECT_ROLE_APPLICATION,
  SET_IPMA_PROJECT_ROLE_APPLICATION,
  LOAD_IPMA_PROJECT_ROLE_APPLICATIONS,
  SET_IPMA_PROJECT_ROLE_APPLICATIONS,
  LOAD_IPMA_PROJECT_ROLE_APPLICATION_BAR,
  SET_IPMA_PROJECT_ROLE_APPLICATION_BAR,
  SET_IPMA_PROJECT_ROLE_APPLICATION_PAGE,
  SET_IPMA_PROJECT_ROLE_APPLICATION_PER_PAGE,
  SET_IPMA_PROJECT_ROLE_APPLICATION_STATUS,
  ACCEPT_IPMA_PROJECT_ROLE_APPLICATION,
  DECLINE_IPMA_PROJECT_ROLE_APPLICATION,
  PUT_ON_HOLD_IPMA_PROJECT_ROLE_APPLICATION,
  DOWNLOAD_IPMA_APPLICATIONS,
  SET_YC_PEOPLE_USERS_FILTERS,
  LOAD_YC_PEOPLE_USERS_FILTERS,
  SET_YC_PEOPLE_LIST_FILTERS,
  LOAD_YC_PEOPLE_LIST_FILTERS,
  DOWNLOAD_YC_APPLICATIONS,
  SET_GLOBAL_ABOUT_IPMA,
  LOAD_GLOBAL_ABOUT_IPMA,
  SET_USER_PENDING_CERTIFICATIONS,
  LOAD_USER_PENDING_CERTIFICATIONS,
  CLAIM_USER_CERTIFICATE,
  REMOVE_IPMA_PROJECT,
  REMOVE_IPMA_PROJECT_ROLE,
  REMOVE_YC_PROJECT,
  REMOVE_YC_PROJECT_ROLE,
  LOAD_USER_CPD_LIST,
  SET_USER_CPD_LIST,
  USER_APPLY_FOR_CPD,
  LOAD_CPD_CATEGORIES,
  LOAD_MY_LOCAL_LIBRARY,
  SET_CPD_CATEGORIES,
  LOAD_MA_CPD_REQUESTS,
  SET_MA_CPD_REQUESTS,
  LOAD_MA_CPD_REQUESTS_BAR,
  SET_MA_CPD_REQUESTS_BAR,
  SET_MA_CPD_REQUESTS_PAGE,
  SET_MA_CPD_REQUESTS_PER_PAGE,
  SET_MA_CPD_REQUESTS_STATUS,
  ACCEPT_CPD_REQUEST,
  REJECT_CPD_REQUEST,
  SET_CPD_ROLES,
  LOAD_CPD_ROLES,
  LOAD_GLOBAL_AVAILABLE_CB,
  SET_GLOBAL_AVAILABLE_CB,
  SEND_USER_CPD_FOR_VERIFICATION,
  LOAD_MA_CPD_BATCH_REQUESTS,
  SET_MA_CPD_BATCH_REQUESTS,
  SET_MA_CPD_BATCH_REQUESTS_PAGE,
  SET_MA_CPD_BATCH_REQUESTS_PER_PAGE,
  LOAD_MA_CPD_BATCH_REQUESTS_LIST,
  SET_MA_CPD_BATCH_REQUESTS_LIST,
  SAVE_USER_BATCH_CPDS,
  EXPORT_USER_BATCH_CPD,
  EXPORT_USER_CPD_CSV,
  SET_AVA_ORGANISATIONS,
  LOAD_AVA_ORGANISATIONS,
  SET_CURRENT_ORGANISATIONS,
  LOAD_CURRENT_ORGANISATIONS,
  SET_MY_LOCAL_LIBRARY,
} from './constants';

export const loadUserAddListCertificatesCreator = (payload, asUser) => ({
  type: LOAD_USER_ADD_LIST_CERTIFICATES,
  payload,
  asUser,
});

export const setUserAddListCertificatesCreator = (payload) => ({
  type: SET_USER_ADD_LIST_CERTIFICATES,
  payload,
});

export const setUserAddListFiltrationCreator = (payload) => ({
  type: SET_USER_ADD_LIST_FILTRATION,
  payload,
});

export const setUserAddListSelectedCertificateCreator = (payload) => ({
  type: SET_USER_ADD_LIST_SELECTED_CERTIFICATE,
  payload,
});

export const addUserAddListCertificatesCreator = (history) => ({
  type: ADD_USER_ADD_LIST_CERTIFICATES,
  history,
});

export const validateRegistrationUserDataCreator = (payload, history) => ({
  type: VALIDATE_REGISTRATION_USER_DATA,
  payload,
  history,
});

export const setRegistrationUserDataCreator = (payload) => ({
  type: SET_REGISTRATION_USER_DATA,
  payload,
});

export const setLoginUserDataCreator = (payload) => ({
  type: SET_LOGIN_USER_DATA,
  payload,
});

export const getRegistrationUserDataCreator = () => ({
  type: GET_REGISTRATION_USER_DATA,
});

export const registerUserCreator = (history, payload, setErrors) => ({
  type: REGISTER_USER,
  history,
  payload,
  setErrors,
});

export const setRegisterErrors = (payload) => ({
  type: SET_REGISTER_ERRORS,
  payload,
});

export const resendRegistrationActivateCreator = (history) => ({
  type: RESEND_REIGSTRATION_ACTIVATE,
  history,
});

export const loginUserCreator = (payload, history, ipmaShopVerify) => ({
  type: LOGIN_USER,
  payload,
  history,
  ipmaShopVerify,
});

export const setLoginUserLoadingCreate = (payload) => ({
  type: SET_LOGIN_USER_LOADING,
  payload,
});

export const resetAppCreator = () => ({
  type: RESET_APP,
});

export const logoutUserCreator = (history) => ({
  type: LOGOUT_USER,
  history,
});

export const showActivationAlertCreator = () => ({
  type: SHOW_ACTIVATION_ALERT,
});

export const setUserCreator = (payload) => ({
  type: SET_USER,
  payload,
});

export const forgotPasswordCreator = (payload, setErrors) => ({
  type: FORGOT_PASSWORD,
  payload,
  setErrors,
});

export const resetPasswordCreator = (payload, token, setErrors, history) => ({
  type: RESET_PASSWORD,
  payload,
  token,
  setErrors,
  history,
});

export const setNewAlertCreator = (payload) => ({
  type: SET_NEW_ALERT,
  payload,
});

export const removeAlertCreator = (payload) => ({
  type: REMOVE_ALERT,
  payload,
});

export const checkTokenCreator = (
  history,
  afterLogin = false,
  token = null,
  ipmaShopVerify = false,
) => ({
  type: CHECK_TOKEN,
  history,
  afterLogin,
  token,
  ipmaShopVerify,
});
export const setCheckedTokenCreator = (payload = true) => ({
  type: SET_CHECKED_TOKEN,
  payload,
});

export const loadRegistrationCertsFiltersCreator = () => ({
  type: LOAD_REGISTRATION_CERTS_FILTERS,
});

export const editProfileCreator = (payload) => ({
  type: EDIT_PROFILE,
  payload,
});

export const setEditProfileLoadingCreator = (payload) => ({
  type: SET_EDIT_PROFILE_LOADING,
  payload,
});

export const setEditProfileErrorsCreator = (payload) => ({
  type: SET_EDIT_PROFILE_ERRORS,
  payload,
});

export const loadGlobalCountriesCreator = () => ({
  type: LOAD_GLOBAL_COUNTRIES,
});

export const setGlobalCountriesCreator = (payload) => ({
  type: SET_GLOBAL_COUNTRIES,
  payload,
});

export const loadGlobalAvailableMACreator = (payload) => ({
  type: LOAD_GLOBAL_AVAILABLE_MA,
  payload,
});

export const setGlobalAvailableMACreator = (payload) => ({
  type: SET_GLOBAL_AVAILABLE_MA,
  payload,
});

export const loadGlobalProfessionalTitlesCreator = () => ({
  type: LOAD_GLOBAL_PROFESSIONAL_TITLES,
});

export const setGlobalProfessionalTitlesCreator = (payload) => ({
  type: SET_GLOBAL_PROFESSIONAL_TITLES,
  payload,
});

export const loadGlobalJobsTitlesCreator = () => ({
  type: LOAD_GLOBAL_JOBS_TITLES,
});

export const setGlobalJobsTitlesCreator = (payload) => ({
  type: SET_GLOBAL_JOBS_TITLES,
  payload,
});

export const loadGlobalProfilePrivacyCreator = () => ({
  type: LOAD_GLOBAL_PROFILE_PRIVACY,
});

export const setGlobalProfilePrivacyCreator = (payload) => ({
  type: SET_GLOBAL_PROFILE_PRIVACY,
  payload,
});

export const loadGlobalGenderCreator = () => ({
  type: LOAD_GLOBAL_GENDER,
});

export const setGlobalGenderCreator = (payload) => ({
  type: SET_GLOBAL_GENDER,
  payload,
});

export const loadGlobalLanguagesCreator = () => ({
  type: LOAD_GLOBAL_LANGUAGES,
});

export const setGlobalLanguagesCreator = (payload) => ({
  type: SET_GLOBAL_LANGUAGES,
  payload,
});

export const loadGlobalTimezoneCreator = () => ({
  type: LOAD_GLOBAL_TIMEZONES,
});
export const setGlobalTimezoneCreator = (payload) => ({
  type: SET_GLOBAL_TIMEZONES,
  payload,
});

export const loadProfileCreator = () => ({
  type: LOAD_PROFILE,
});

export const setProfileCreator = (payload) => ({
  type: SET_PROFILE,
  payload,
});

export const editPasswordCreator = (payload, history) => ({
  type: EDIT_PASSWORD,
  payload,
  history,
});

export const setEditPasswordLoadingCreator = (payload) => ({
  type: SET_EDIT_PASSWORD_LOADING,
  payload,
});

export const setEditPasswordErrorsCreator = (payload) => ({
  type: SET_EDIT_PASSWORD_ERRORS,
  payload,
});

export const deleteProfileCreator = (payload, history) => ({
  type: DELETE_PROFILE,
  payload,
  history,
});

export const setDeleteProfileLoadingCreator = (payload) => ({
  type: SET_DELETE_PROFILE_LOADING,
  payload,
});

export const setDeleteProfileErrorsCreator = (payload) => ({
  type: SET_DELETE_PROFILE_ERRORS,
  payload,
});

export const createOrganisationCreator = (payload, history, reset) => ({
  type: CREATE_ORGANISATION,
  payload,
  history,
  reset,
});

export const setCreateOrganisationLoadingCreator = (payload) => ({
  type: SET_CREATE_ORGANISATION_LOADING,
  payload,
});

export const setCreateOrganisationErrorsCreator = (payload) => ({
  type: SET_CREATE_ORGANISATION_ERRORS,
  payload,
});

export const loadUserOrganisationCreator = (payload) => ({
  type: LOAD_USER_ORGANISATION,
  payload,
});

export const setUserOrganisationCreator = (payload) => ({
  type: SET_USER_ORGANISATION,
  payload,
});

export const updateUserOrganisationCreator = (id, payload, history) => ({
  type: UPDATE_USER_ORGANISATION,
  id,
  payload,
  history,
});

export const loadUserOrganisationPeopleCreator = (payload) => ({
  type: LOAD_USER_ORGANISATION_PEOPLE,
  payload,
});

export const setUserOrganisationPeopleCreator = (payload) => ({
  type: SET_USER_ORGANISATION_PEOPLE,
  payload,
});

export const setUserOrganisationPeoplePagesCreator = (payload) => ({
  type: SET_USER_ORGANISATION_PEOPLE_PAGES,
  payload,
});

export const setUserOrganisationPeoplePageCreator = (payload) => ({
  type: SET_USER_ORGANISATION_PEOPLE_PAGE,
  payload,
});

export const setUserOrganisationPeoplePerPageCreator = (payload) => ({
  type: SET_USER_ORGANISATION_PEOPLE_PER_PAGE,
  payload,
});

export const loadUserOrganisationPeopleAddCreator = (payload) => ({
  type: LOAD_USER_ORGANISATION_PEOPLE_ADD,
  payload,
});

export const setUserOrganisationPeopleAddCreator = (payload) => ({
  type: SET_USER_ORGANISATION_PEOPLE_ADD,
  payload,
});

export const addUserOrganisationPeopleAddCreator = (payload, history) => ({
  type: ADD_USER_ORGANISATION_PEOPLE_ADD,
  payload,
  history,
});

export const removeUserOrganisationPeopleCreator = (payload) => ({
  type: REMOVE_USER_ORGANISATION_PEOPLE,
  payload,
});

export const setOrganisationPersonResponsibleCreator = (payload) => ({
  type: SET_ORGANISATION_PERSON_RESPONSIBLE,
  payload,
});

export const updateOrganisationPersonResponsibleCreator = (payload) => ({
  type: UPDATE_ORGANISATION_PERSON_RESPONSIBLE,
  payload,
});

export const loadOrganisationDashboardCoursesCreator = () => ({
  type: LOAD_ORGANISATION_DASHBOARD_COURSES,
});

export const setOrganisationDashboardCoursesCreator = (payload) => ({
  type: SET_ORGANISATION_DASHBOARD_COURSES,
  payload,
});

export const loadOrganisationProfileCoursesCreator = (payload) => ({
  type: LOAD_ORGANISATION_PROFILE_COURSES,
  payload,
});

export const setOrganisationProfileCoursesCreator = (payload) => ({
  type: SET_ORGANISATION_PROFILE_COURSES,
  payload,
});

export const setAppTypeCreator = (payload) => ({
  type: SET_APP_TYPE,
  payload,
});

export const setUserPermissionsCreator = (payload) => ({
  type: SET_USER_PERMISSIONS,
  payload,
});

export const setUserMemberAssociationPeople = (payload) => ({
  type: SET_USER_MEMBER_ASSOCIATION_PEOPLE,
  payload,
});

export const setNewLoadingCreator = (payload) => ({
  type: SET_NEW_LOADING,
  payload,
});

export const removeLoadingCreator = (payload) => ({
  type: REMOVE_LOADING,
  payload,
});

export const loadGlobalCourseDeliveryModesCreator = () => ({
  type: LOAD_GLOBAL_COURSE_DELIVERY_MODES,
});

export const setGlobalCourseDeliveryModesCreator = (payload) => ({
  type: SET_GLOBAL_COURSE_DELIVERY_MODES,
  payload,
});

export const loadGlobalCourseCourseTypesCreator = () => ({
  type: LOAD_GLOBAL_COURSE_COURSE_TYPES,
});

export const setGlobalCourseCourseTypesCreator = (payload) => ({
  type: SET_GLOBAL_COURSE_COURSE_TYPES,
  payload,
});

export const loadGlobalCourseInstructionFormatsCreator = () => ({
  type: LOAD_GLOBAL_COURSE_INSTRUCTION_FORMATS,
});

export const setGlobalCourseInstructionFormatsCreator = (payload) => ({
  type: SET_GLOBAL_COURSE_INSTRUCTION_FORMATS,
  payload,
});

export const loadGlobalCoursePerspectiveCompetenciesCreator = () => ({
  type: LOAD_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES,
});

export const setGlobalCoursePerspectiveCompetenciesCreator = (payload) => ({
  type: SET_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES,
  payload,
});

export const loadGlobalCoursePeopleCompetenciesCreator = () => ({
  type: LOAD_GLOBAL_COURSE_PEOPLE_COMPETENCIES,
});

export const setGlobalCoursePeopleCompetenciesCreator = (payload) => ({
  type: SET_GLOBAL_COURSE_PEOPLE_COMPETENCIES,
  payload,
});

export const loadGlobalCoursePracticeCompetenciesCreator = () => ({
  type: LOAD_GLOBAL_COURSE_PRACTICE_COMPETENCIES,
});

export const setGlobalCoursePracticeCompetenciesCreator = (payload) => ({
  type: SET_GLOBAL_COURSE_PRACTICE_COMPETENCIES,
  payload,
});

export const loadGlobalCourseCloudTagsCreator = () => ({
  type: LOAD_GLOBAL_COURSE_CLOUD_TAGS,
});

export const setGlobalCourseCloudTagsCreator = (payload) => ({
  type: SET_GLOBAL_COURSE_CLOUD_TAGS,
  payload,
});

export const loadGlobalCourseAttributesCreator = () => ({
  type: LOAD_GLOBAL_COURSE_ATTRIBUTES,
});

export const loadGlobalCurrenciesCreator = () => ({
  type: LOAD_GLOBAL_CURRENCIES,
});

export const setGlobalCurrenciesCreator = (payload) => ({
  type: SET_GLOBAL_CURRENCIES,
  payload,
});

export const loadGlobalEventDeliveryModeCreator = () => ({
  type: LOAD_GLOBAL_EVENT_DELIVERY_MODE,
});

export const setGlobalEventDeliveryModeCreator = (payload) => ({
  type: SET_GLOBAL_EVENT_DELIVERY_MODE,
  payload,
});

export const loadGlobalEventAttributesCreator = () => ({
  type: LOAD_GLOBAL_EVENT_ATTRIBUTES,
});

export const loadGlobalContactSubjectsCreator = () => ({
  type: LOAD_GLOBAL_CONTACT_SUBJECTS,
});

export const setGlobalContactSubjectsCreator = (payload) => ({
  type: SET_GLOBAL_CONTACT_SUBJECTS,
  payload,
});

export const loadGlobalIndustriesCreator = () => ({
  type: LOAD_GLOBAL_INDUSTRIES,
});

export const setGlobalIndustriesCreator = (payload) => ({
  type: SET_GLOBAL_INDUSTRIES,
  payload,
});

export const createCourseCreator = (payload, asDraft, history) => ({
  type: CREATE_COURSE,
  payload,
  asDraft,
  history,
});

export const setCreateCourseErrorsCreator = (payload) => ({
  type: SET_CREATE_COURSE_ERRORS,
  payload,
});

export const updateCourseDraftCreator = (payload, history) => ({
  type: UPDATE_COURSE_DRAFT,
  payload,
  history,
});

export const updateSubmittedTemplateCourseCreator = (payload, history) => ({
  type: UPDATE_SUBMITTED_TEMPLATE_COURSE,
  payload,
  history,
});

export const loadMaPeopleBarCreator = (payload) => ({
  type: LOAD_MA_PEOPLE_BAR,
  payload,
});

export const setMaPeopleBarCreator = (payload) => ({
  type: SET_MA_PEOPLE_BAR,
  payload,
});

export const loadMaPeopleUsersCreator = () => ({
  type: LOAD_MA_PEOPLE_USERS,
});

export const setMaPeopleUsersCreator = (payload) => ({
  type: SET_MA_PEOPLE_USERS,
  payload,
});
export const setMaPeopleUsersSortCreator = (payload) => ({
  type: SET_MA_PEOPLE_USERS_SORT,
  payload,
});

export const changeMaPeopleUsersRoleCreator = (payload) => ({
  type: CHANGE_MA_PEOPLE_USERS_ROLE,
  payload,
});

export const deleteMaPeopleUserCreator = (payload) => ({
  type: DELETE_MA_PEOPLE_USER,
  payload,
});
export const setMaPeopleUsersPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_USERS_PAGE,
  payload,
});

export const setMaPeopleUsersPerPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_USERS_PER_PAGE,
  payload,
});

export const loadMaPeopleListCreator = () => ({
  type: LOAD_MA_PEOPLE_LIST,
});

export const setMaPeopleListCreator = (payload) => ({
  type: SET_MA_PEOPLE_LIST,
  payload,
});

export const setMaPeopleListSortCreator = (payload) => ({
  type: SET_MA_PEOPLE_LIST_SORT,
  payload,
});

export const setMaPeopleListPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_LIST_PAGE,
  payload,
});

export const setMaPeopleListPerPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_LIST_PER_PAGE,
  payload,
});

export const changeMaPeopleListRoleCreator = (payload) => ({
  type: CHANGE_MA_PEOPLE_LIST_ROLE,
  payload,
});

export const acceptMaPeopleListUserCreator = (payload) => ({
  type: ACCEPT_MA_PEOPLE_LIST_USER,
  payload,
});

export const declineMaPeopleListUserCreator = (payload) => ({
  type: DECLINE_MA_PEOPLE_LIST_USER,
  payload,
});

export const removeMaPeopleListUserCreator = (payload) => ({
  type: REMOVE_MA_PEOPLE_LIST_USER,
  payload,
});

export const loadMaPeopleMembersCreator = () => ({
  type: LOAD_MA_PEOPLE_MEMBERS,
});

export const setMaPeopleMembersCreator = (payload) => ({
  type: SET_MA_PEOPLE_MEMBERS,
  payload,
});

export const setMaPeopleMembersSortCreator = (payload) => ({
  type: SET_MA_PEOPLE_MEMBERS_SORT,
  payload,
});

export const setMaPeopleMembersPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_MEMBERS_PAGE,
  payload,
});

export const setMaPeopleMembersPerPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_MEMBERS_PER_PAGE,
  payload,
});

export const removeMaPeopleMembersUserCreator = (payload) => ({
  type: REMOVE_MA_PEOPLE_MEMBERS_USER,
  payload,
});

export const loadMaPeopleAddListUsersCreator = (payload) => ({
  type: LOAD_MA_PEOPLE_ADD_LIST_USERS,
  payload,
});

export const setMaPeopleAddListUsersCreator = (payload) => ({
  type: SET_MA_PEOPLE_ADD_LIST_USERS,
  payload,
});

export const addMaPeopleUsersCreator = (payload, history) => ({
  type: ADD_MA_PEOPLE_USERS,
  payload,
  history,
});

export const loadMaPeopleOrganisationsCreator = () => ({
  type: LOAD_MA_PEOPLE_ORGANISATIONS,
});

export const setMaPeopleOrganisationsCreator = (payload) => ({
  type: SET_MA_PEOPLE_ORGANISATIONS,
  payload,
});

export const setMaPeopleOrganisationsSortCreator = (payload) => ({
  type: SET_MA_PEOPLE_ORGANISATIONS_SORT,
  payload,
});

export const setMaPeopleOrganisationsPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_ORGANISATIONS_PAGE,
  payload,
});

export const setMaPeopleOrganisationsPerPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_ORGANISATIONS_PER_PAGE,
  payload,
});

export const loadGlobalMaRolesCreator = () => ({
  type: LOAD_GLOBAL_MA_ROLES,
});

export const setGlobalMaRolesCreator = (payload) => ({
  type: SET_GLOBAL_MA_ROLES,
  payload,
});

export const loadGlobalOrganisationRolesCreator = () => ({
  type: LOAD_GLOBAL_ORGANISATION_ROLES,
});

export const setGlobalOrganisationRolesCreator = (payload) => ({
  type: SET_GLOBAL_ORGANISATION_ROLES,
  payload,
});

export const loadMaProfileCreator = () => ({
  type: LOAD_MA_PROFILE,
});

export const setMaProfileCreator = (payload) => ({
  type: SET_MA_PROFILE,
  payload,
});

export const setMaProfileUsersRoleCreator = (payload) => ({
  type: SET_MA_PROFILE_USERS_ROLE,
  payload,
});

export const updateMaProfileCreator = (payload) => ({
  type: UPDATE_MA_PROFILE,
  payload,
});

export const setUpdateMaProfileErrorsCreator = (payload) => ({
  type: SET_UPDATE_MA_PROFILE_ERRORS,
  payload,
});

export const loadMaDashboardCreator = () => ({
  type: LOAD_MA_DASHBOARD,
});

export const setMaDashboardCreator = (payload) => ({
  type: SET_MA_DASHBOARD,
  payload,
});

export const loadOrganisationDashboardCreator = (payload) => ({
  type: LOAD_ORGANISATION_DASHBOARD,
  payload,
});

export const setOrganisationDashboardCreator = (payload) => ({
  type: SET_ORGANISATION_DASHBOARD,
  payload,
});

export const loadOrganisationCourseCompletionCreator = (payload) => ({
  type: LOAD_ORGANISATION_COURSE_COMPLETION,
  payload,
});

export const setOrganisationCourseCompletionCreator = (payload) => ({
  type: SET_ORGANISATION_COURSE_COMPLETION,
  payload,
});

export const setOrganisationCourseCompletionUsersCreator = (payload) => ({
  type: SET_ORGANISATION_COURSE_COMPLETION_USERS,
  payload,
});

export const setOrganisationCourseCompletionUserStatusCreator = (payload) => ({
  type: SET_ORGANISATION_COURSE_COMPLETION_USER_STATUS,
  payload,
});

export const setOrganisationCourseCompletionSortCreator = (payload) => ({
  type: SET_ORGANISATION_COURSE_COMPLETION_SORT,
  payload,
});

export const changeOrganisationUserRoleCreator = (payload) => ({
  type: CHANGE_ORGANISATION_USER_ROLE,
  payload,
});

export const loadNotificationsCreator = () => ({
  type: LOAD_NOTIFICATIONS,
});

export const setNotificationsCreator = (payload) => ({
  type: SET_NOTIFICATIONS,
  payload,
});

export const removeNotificationCreator = (payload) => ({
  type: REMOVE_NOTIFICATION,
  payload,
});

export const removeAllNotificationCreator = () => ({
  type: REMOVE_ALL_NOTIFICATIONS,
});

export const loadUserCertificationsCreator = () => ({
  type: LOAD_USER_CERTIFICATIONS,
});

export const setUserCertificationsCreator = (payload) => ({
  type: SET_USER_CERTIFICATIONS,
  payload,
});

export const removeUserCertificationsCreator = (payload) => ({
  type: REMOVE_USER_CERTIFICATIONS,
  payload,
});

export const loadListCreator = (listType, options) => ({
  type: LOAD_LIST,
  listType,
  options,
});

export const setListCreator = (listType, payload) => ({
  type: SET_LIST,
  listType,
  payload,
});

export const setListPageCreator = (listType, payload) => ({
  type: SET_LIST_PAGE,
  listType,
  payload,
});

export const setListPagesCreator = (listType, payload) => ({
  type: SET_LIST_PAGES,
  listType,
  payload,
});

export const setListPerPageCreator = (listType, payload) => ({
  type: SET_LIST_PER_PAGE,
  listType,
  payload,
});

export const setListSortCreator = (listType, payload) => ({
  type: SET_LIST_SORT,
  listType,
  payload,
});

export const setListFiltersCreator = (listType, payload) => ({
  type: SET_LIST_FILTERS,
  listType,
  payload,
});

export const removeListFiltersCreator = (listType) => ({
  type: REMOVE_LIST_FILTERS,
  listType,
});

export const loadListViewCreator = (listType, payload, history) => ({
  type: LOAD_LIST_VIEW,
  listType,
  payload,
  history,
});

export const setListViewCreator = (listType, payload) => ({
  type: SET_LIST_VIEW,
  listType,
  payload,
});

export const removeListViewCreator = (listType) => ({
  type: REMOVE_LIST_VIEW,
  listType,
});

export const createEventCreator = (payload, history, setErrors) => ({
  type: CREATE_EVENT,
  payload,
  history,
  setErrors,
});

export const loadMaEventsCreator = (payload) => ({
  type: LOAD_MA_EVENTS,
  payload,
});

export const setMaEventsCreator = (payload) => ({
  type: SET_MA_EVENTS,
  payload,
});

export const loadMaEventsBarCreator = (payload) => ({
  type: LOAD_MA_EVENTS_BAR,
  payload,
});

export const setMaEventsBarCreator = (payload) => ({
  type: SET_MA_EVENTS_BAR,
  payload,
});

export const setMaEventsPageCreator = (payload) => ({
  type: SET_MA_EVENTS_PAGE,
  payload,
});

export const setMaEventsPerPageCreator = (payload) => ({
  type: SET_MA_EVENTS_PER_PAGE,
  payload,
});

export const setMaEventsStatusCreator = (payload) => ({
  type: SET_MA_EVENTS_STATUS,
  payload,
});

export const cancelMaEventCreator = (payload) => ({
  type: CANCEL_MA_EVENT,
  payload,
});

export const reopenMaEventCreator = (payload) => ({
  type: REOPEN_MA_EVENT,
  payload,
});

export const loadMaEventCreator = (payload, setEvent) => ({
  type: LOAD_MA_EVENT,
  payload,
  setEvent,
});

export const loadMaProfileUcomingEventsCreator = () => ({
  type: LOAD_MA_PROFILE_UPCOMING_EVENTS,
});

export const setMaProfileUcomingEventsCreator = (payload) => ({
  type: SET_MA_PROFILE_UPCOMING_EVENTS,
  payload,
});

export const editMaEventCreator = (payload, id, history, setErrors) => ({
  type: EDIT_MA_EVENT,
  payload,
  id,
  history,
  setErrors,
});

export const duplicateMaEventCreator = (payload) => ({
  type: DUPLICATE_MA_EVENT,
  payload,
});

export const applyUserToMaCreator = (payload) => ({
  type: APPLY_USER_TO_MA,
  payload,
});

export const loadPageHomeCreator = () => ({
  type: LOAD_PAGE_HOME,
});

export const setPageHomeCreator = (payload) => ({
  type: SET_PAGE_HOME,
  payload,
});

export const loadPageUserProfileCreator = (payload, history) => ({
  type: LOAD_PAGE_USER_PROFILE,
  payload,
  history,
});

export const setPageUserProfileCreator = (payload) => ({
  type: SET_PAGE_USER_PROFILE,
  payload,
});

export const loadPageUserProfileBadgesCreator = (payload) => ({
  type: LOAD_PAGE_USER_PROFILE_BADGES,
  payload,
});

export const setPageUserProfileBadgesCreator = (payload) => ({
  type: SET_PAGE_USER_PROFILE_BADGES,
  payload,
});

export const updateUserEmailCreator = (payload) => ({
  type: UPDATE_USER_EMAIL,
  payload,
});

export const loadSearchTotalsCreator = (payload) => ({
  type: LOAD_SEARCH_TOTALS,
  payload,
});

export const setSearchTotalsCreator = (payload) => ({
  type: SET_SEARCH_TOTALS,
  payload,
});

export const loadSearchCreator = (listType, payload) => ({
  type: LOAD_SEARCH,
  listType,
  payload,
});

export const setSearchCreator = (listType, payload) => ({
  type: SET_SEARCH,
  listType,
  payload,
});

export const setSearchPagesCreator = (listType, payload) => ({
  type: SET_SEARCH_PAGES,
  listType,
  payload,
});

export const setSearchPageCreator = (listType, payload) => ({
  type: SET_SEARCH_PAGE,
  listType,
  payload,
});

export const setSearchPerPageCreator = (listType, payload) => ({
  type: SET_SEARCH_PER_PAGE,
  listType,
  payload,
});

export const loadUserDashboardCreator = () => ({
  type: LOAD_USER_DASHBOARD,
});

export const setUserDashboardCreator = (payload) => ({
  type: SET_USER_DASHBOARD,
  payload,
});

export const applyUserToCourseCreator = (payload) => ({
  type: APPLY_USER_TO_COURSE,
  payload,
});

export const declineUserFromCourseCreator = (payload) => ({
  type: DECLINE_USER_FROM_COURSE,
  payload,
});

export const loadProfileCompletedCoursesCreator = () => ({
  type: LOAD_PROFILE_COMPLETED_COURSES,
});

export const setProfileCompletedCoursesCreator = (payload) => ({
  type: SET_PROFILE_COMPLETED_COURSES,
  payload,
});

export const submitPageContactFromCreator = (payload, history, setErrors) => ({
  type: SUBMIT_PAGE_CONTACT_FORM,
  payload,
  history,
  setErrors,
});

export const loadMaCoursesCreator = (listType) => ({
  type: LOAD_MA_COURSES,
  listType,
});

export const setMaCoursesCreator = (listType, payload) => ({
  type: SET_MA_COURSES,
  listType,
  payload,
});

export const setMaCoursesPagesCreator = (listType, payload) => ({
  type: SET_MA_COURSES_PAGES,
  listType,
  payload,
});

export const setMaCoursesPageCreator = (listType, payload) => ({
  type: SET_MA_COURSES_PAGE,
  listType,
  payload,
});

export const setMaCoursesPerPageCreator = (listType, payload) => ({
  type: SET_MA_COURSES_PER_PAGE,
  listType,
  payload,
});

export const loadMaCoursesBarCreator = (payload) => ({
  type: LOAD_MA_COURSES_BAR,
  payload,
});

export const setMaCoursesBarCreator = (payload) => ({
  type: SET_MA_COURSES_BAR,
  payload,
});

export const loadMaAssessorsCreator = () => ({
  type: LOAD_MA_ASSESSORS,
});

export const setMaAssessorsCreator = (payload) => ({
  type: SET_MA_ASSESSORS,
  payload,
});

export const acceptMaCourseCreator = (listType, payload) => ({
  type: ACCEPT_MA_COURSE,
  listType,
  payload,
});

export const cancelMaCourseCreator = (listType, payload) => ({
  type: CANCEL_MA_COURSE,
  listType,
  payload,
});

export const changeMaCoursesAssessorCreator = (listType, payload) => ({
  type: CHANGE_MA_COURSES_ASSESSOR,
  listType,
  payload,
});

export const loadUserCoursesCreator = (listType) => ({
  type: LOAD_USER_COURSES,
  listType,
});

export const setUserCoursesCreator = (listType, payload) => ({
  type: SET_USER_COURSES,
  listType,
  payload,
});

export const setUserCoursesPagesCreator = (listType, payload) => ({
  type: SET_USER_COURSES_PAGES,
  listType,
  payload,
});

export const setUserCoursesPageCreator = (listType, payload) => ({
  type: SET_USER_COURSES_PAGE,
  listType,
  payload,
});

export const setUserCoursesPerPageCreator = (listType, payload) => ({
  type: SET_USER_COURSES_PER_PAGE,
  listType,
  payload,
});

export const loadUserCoursesBarCreator = (payload) => ({
  type: LOAD_USER_COURSES_BAR,
  payload,
});

export const setUserCoursesBarCreator = (payload) => ({
  type: SET_USER_COURSES_BAR,
  payload,
});

export const loadOrganisationCoursesCreator = (listType, organization) => ({
  type: LOAD_ORGANISATION_COURSES,
  listType,
  organization,
});

export const setOrganisationCoursesCreator = (listType, payload) => ({
  type: SET_ORGANISATION_COURSES,
  listType,
  payload,
});

export const setOrganisationCoursesPagesCreator = (listType, payload) => ({
  type: SET_ORGANISATION_COURSES_PAGES,
  listType,
  payload,
});

export const setOrganisationCoursesPageCreator = (listType, payload) => ({
  type: SET_ORGANISATION_COURSES_PAGE,
  listType,
  payload,
});

export const setOrganisationCoursesPerPageCreator = (listType, payload) => ({
  type: SET_ORGANISATION_COURSES_PER_PAGE,
  listType,
  payload,
});

export const loadOrganisationCoursesBarCreator = (payload) => ({
  type: LOAD_ORGANISATION_COURSES_BAR,
  payload,
});

export const setOrganisationCoursesBarCreator = (payload) => ({
  type: SET_ORGANISATION_COURSES_BAR,
  payload,
});

export const cancelOrganisationCourseCreator = (listType, payload) => ({
  type: CANCEL_ORGANISATION_COURSE,
  listType,
  payload,
});

export const loadOrganisationCoursePublishCreator = (payload, history, cb) => ({
  type: LOAD_ORGANISATION_COURSE_PUBLISH,
  payload,
  history,
  cb,
});

export const applyOrganisationCoursePublishCreator = (payload, history, setErrors) => ({
  type: APPLY_ORGANISATION_COURSE_PUBLISH,
  payload,
  history,
  setErrors,
});

export const loadSubcourseParentCreator = (payload, history, cb) => ({
  type: LOAD_SUBCOURSE_PARENT,
  payload,
  history,
  cb,
});

export const createSubcourseCreator = (payload, history, setErrors) => ({
  type: CREATE_SUBCOURSE,
  payload,
  history,
  setErrors,
});

export const loadHomeSliderCreator = () => ({
  type: LOAD_HOME_SLIDER,
});

export const setHomeSliderCreator = (payload) => ({
  type: SET_HOME_SLIDER,
  payload,
});

export const appAddToNewsletterCreator = (payload, cb) => ({
  type: APP_ADD_TO_NEWSLETTER,
  payload,
  cb,
});

export const loadUserBadgesCreator = () => ({
  type: LOAD_USER_BADGES,
});

export const setUserBadgesCreator = (payload) => ({
  type: SET_USER_BADGES,
  payload,
});

export const claimUserBadgeCreator = (payload, cb) => ({
  type: CLAIM_USER_BADGE,
  payload,
  cb,
});

export const connectAzureCreator = () => ({
  type: CONNECT_AZURE,
});

export const loginSocialCreator = (payload) => ({
  type: SOCIAL_LOGIN,
  payload,
});

export const authSocialCreator = (payload, history) => ({
  type: SOCIAL_AUTH,
  payload,
  history,
});
export const setIpmaDashboardCreator = (payload) => ({
  type: SET_IPMA_DASHBOARD,
  payload,
});

export const loadIpmaDashboardCreator = () => ({
  type: LOAD_IPMA_DASHBOARD,
});

export const setIpmaProfileCreator = (payload) => ({
  type: SET_IPMA_PROFILE,
  payload,
});

export const loadIpmaProfileCreator = () => ({
  type: LOAD_IPMA_PROFILE,
});

export const setIpmaProfileUsersRoleCreator = (payload) => ({
  type: SET_IPMA_PROFILE_USERS_ROLE,
  payload,
});

export const setIpmaProfileUcomingEventsCreator = (payload) => ({
  type: SET_IPMA_PROFILE_UPCOMING_EVENTS,
  payload,
});

export const loadIpmaProfileUcomingEventsCreator = () => ({
  type: LOAD_IPMA_PROFILE_UPCOMING_EVENTS,
});

export const setUpdateIpmaProfileErrorsCreator = (payload) => ({
  type: SET_UPDATE_IPMA_PROFILE_ERRORS,
  payload,
});

export const updateIpmaProfileCreator = (payload) => ({
  type: UPDATE_IPMA_PROFILE,
  payload,
});

export const loadIpmaPeopleBarCreator = (payload) => ({
  type: LOAD_IPMA_PEOPLE_BAR,
  payload,
});

export const setIpmaPeopleBarCreator = (payload) => ({
  type: SET_IPMA_PEOPLE_BAR,
  payload,
});

export const loadIpmaPeopleUsersCreator = () => ({
  type: LOAD_IPMA_PEOPLE_USERS,
});

export const setIpmaPeopleUsersCreator = (payload) => ({
  type: SET_IPMA_PEOPLE_USERS,
  payload,
});
export const setIpmaPeopleUsersSortCreator = (payload) => ({
  type: SET_IPMA_PEOPLE_USERS_SORT,
  payload,
});

export const changeIpmaPeopleUsersRoleCreator = (payload) => ({
  type: CHANGE_IPMA_PEOPLE_USERS_ROLE,
  payload,
});

export const deleteIpmaPeopleUserCreator = (payload) => ({
  type: DELETE_IPMA_PEOPLE_USER,
  payload,
});
export const setIpmaPeopleUsersPageCreator = (payload) => ({
  type: SET_IPMA_PEOPLE_USERS_PAGE,
  payload,
});

export const setIpmaPeopleUsersPerPageCreator = (payload) => ({
  type: SET_IPMA_PEOPLE_USERS_PER_PAGE,
  payload,
});

export const loadGlobalIpmaRolesCreator = () => ({
  type: LOAD_GLOBAL_IPMA_ROLES,
});

export const setGlobalIpmaRolesCreator = (payload) => ({
  type: SET_GLOBAL_IPMA_ROLES,
  payload,
});

export const loadIpmaPeopleAddListUsersCreator = (payload) => ({
  type: LOAD_IPMA_PEOPLE_ADD_LIST_USERS,
  payload,
});

export const setIpmaPeopleAddListUsersCreator = (payload) => ({
  type: SET_IPMA_PEOPLE_ADD_LIST_USERS,
  payload,
});

export const addIpmaPeopleUsersCreator = (payload, history) => ({
  type: ADD_IPMA_PEOPLE_USERS,
  payload,
  history,
});

export const loadIpmaPeopleListCreator = () => ({
  type: LOAD_IPMA_PEOPLE_LIST,
});

export const loadIpmaEventsCreator = (payload) => ({
  type: LOAD_IPMA_EVENTS,
  payload,
});

export const setIpmaEventsCreator = (payload) => ({
  type: SET_IPMA_EVENTS,
  payload,
});

export const loadIpmaEventsBarCreator = (payload) => ({
  type: LOAD_IPMA_EVENTS_BAR,
  payload,
});

export const setIpmaEventsBarCreator = (payload) => ({
  type: SET_IPMA_EVENTS_BAR,
  payload,
});

export const setIpmaEventsPageCreator = (payload) => ({
  type: SET_IPMA_EVENTS_PAGE,
  payload,
});

export const setIpmaEventsPerPageCreator = (payload) => ({
  type: SET_IPMA_EVENTS_PER_PAGE,
  payload,
});

export const setIpmaEventsStatusCreator = (payload) => ({
  type: SET_IPMA_EVENTS_STATUS,
  payload,
});

export const cancelIpmaEventCreator = (payload) => ({
  type: CANCEL_IPMA_EVENT,
  payload,
});

export const duplicateIpmaEventCreator = (payload) => ({
  type: DUPLICATE_IPMA_EVENT,
  payload,
});

export const reopenIpmaEventCreator = (payload) => ({
  type: REOPEN_IPMA_EVENT,
  payload,
});

export const createIpmaEventCreator = (payload, history, setErrors) => ({
  type: CREATE_IPMA_EVENT,
  payload,
  history,
  setErrors,
});

export const loadIpmaEventCreator = (payload, setEvent) => ({
  type: LOAD_IPMA_EVENT,
  payload,
  setEvent,
});

export const editIpmaEventCreator = (payload, id, history, setErrors) => ({
  type: EDIT_IPMA_EVENT,
  payload,
  id,
  history,
  setErrors,
});

export const ipmaShopSsoCreator = (history) => ({
  type: IPMA_SHOP_SSO,
  history,
});

export const loadIpmaEventParticipantsCreator = (payload) => ({
  type: LOAD_IPMA_EVENT_PARTICIPANTS,
  payload,
});

export const setIpmaEventParticipantsCreator = (payload) => ({
  type: SET_IPMA_EVENT_PARTICIPANTS,
  payload,
});

export const setIpmaEventParticipantsPageCreator = (payload) => ({
  type: SET_IPMA_EVENT_PARTICIPANTS_PAGE,
  payload,
});

export const setIpmaEventParticipantsPerPageCreator = (payload) => ({
  type: SET_IPMA_EVENT_PARTICIPANTS_PER_PAGE,
  payload,
});

export const uploadIpmaEventParticipantsCreator = (id, payload) => ({
  type: UPLOAD_IPMA_EVENT_PARTICIPANTS,
  id,
  payload,
});

export const loadIpmaAwardParticipantsCreator = (payload) => ({
  type: LOAD_IPMA_AWARD_PARTICIPANTS,
  payload,
});

export const setIpmaAwardParticipantsCreator = (payload) => ({
  type: SET_IPMA_AWARD_PARTICIPANTS,
  payload,
});

export const setIpmaAwardParticipantsPageCreator = (payload) => ({
  type: SET_IPMA_AWARD_PARTICIPANTS_PAGE,
  payload,
});

export const setIpmaAwardParticipantsPerPageCreator = (payload) => ({
  type: SET_IPMA_AWARD_PARTICIPANTS_PER_PAGE,
  payload,
});

export const uploadIpmaAwardParticipantsCreator = (id, payload) => ({
  type: UPLOAD_IPMA_AWARD_PARTICIPANTS,
  id,
  payload,
});

export const setIpmaAwardsListCreator = (payload) => ({
  type: SET_IPMA_AWARDS_LIST,
  payload,
});

export const loadIpmaAwardsListCreator = () => ({
  type: LOAD_IPMA_AWARDS_LIST,
});

export const setIpmaAwardsListPageCreator = (payload) => ({
  type: SET_IPMA_AWARDS_LIST_PAGE,
  payload,
});

export const setIpmaAwardsListPerPageCreator = (payload) => ({
  type: SET_IPMA_AWARDS_LIST_PER_PAGE,
  payload,
});

export const loadIpmaRegOfficeCoursesCreator = (payload) => ({
  type: LOAD_IPMA_REG_OFFICE_COURSES,
  payload,
});

export const setIpmaRegOfficeCoursesCreator = (payload) => ({
  type: SET_IPMA_REG_OFFICE_COURSES,
  payload,
});

export const loadIpmaRegOfficeCoursesBarCreator = (payload) => ({
  type: LOAD_IPMA_REG_OFFICE_COURSES_BAR,
  payload,
});

export const setIpmaRegOfficeCoursesBarCreator = (payload) => ({
  type: SET_IPMA_REG_OFFICE_COURSES_BAR,
  payload,
});

export const setIpmaRegOfficeCoursesPageCreator = (payload) => ({
  type: SET_IPMA_REG_OFFICE_COURSES_PAGE,
  payload,
});

export const setIpmaRegOfficeCoursesPerPageCreator = (payload) => ({
  type: SET_IPMA_REG_OFFICE_COURSES_PER_PAGE,
  payload,
});

export const setIpmaRegOfficeCoursesStatusCreator = (payload) => ({
  type: SET_IPMA_REG_OFFICE_COURSES_STATUS,
  payload,
});

export const acceptIpmaRegOfficeCourseCreator = (payload) => ({
  type: ACCEPT_IPMA_REG_OFFICE_COURSE,
  payload,
});

export const rejectIpmaRegOfficeCourseCreator = (payload) => ({
  type: REJECT_IPMA_REG_OFFICE_COURSE,
  payload,
});

export const uploadIpmaRegOfficeCourseRegLogosCreator = (payload) => ({
  type: UPLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  payload,
});

export const loadIpmaRegOfficeCourseRegLogosCreator = (payload) => ({
  type: LOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  payload,
});

export const setIpmaRegOfficeCourseRegLogosCreator = (payload) => ({
  type: SET_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  payload,
});

export const removeIpmaRegOfficeCourseRegLogoCreator = (payload) => ({
  type: REMOVE_IPMA_REG_OFFICE_COURSE_REG_LOGO,
  payload,
});

export const downloadIpmaRegOfficeCourseRegLogoCreator = (payload) => ({
  type: DOWNLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGO,
  payload,
});

export const loadIpmaRegOfficeStatTableCreator = () => ({
  type: LOAD_IPMA_REG_OFFICE_STAT_TABLE,
});

export const setIpmaRegOfficeStatTableCreator = (payload) => ({
  type: SET_IPMA_REG_OFFICE_STAT_TABLE,
  payload,
});

export const loadIpmaRegOfficeGoogleLinkCreator = (payload) => ({
  type: LOAD_IPMA_REG_OFFICE_GOOGLE_LINK,
  payload,
});

export const setIpmaRegOfficeGoogleLinkCreator = (payload) => ({
  type: SET_IPMA_REG_OFFICE_GOOGLE_LINK,
  payload,
});

export const toggleIpmaRegOfficeMaTypeCreator = (payload) => ({
  type: TOGGLE_REG_OFFICE_MA_TYPE,
  payload,
});

export const toggleIpmaRegOfficeOrgFrCreator = (payload) => ({
  type: TOGGLE_REG_OFFICE_ORGANIZATION_FR,
  payload,
});

export const sendMaReportCreator = (payload, history) => ({
  type: SEND_MA_REPORT,
  payload,
  history,
});

export const updateReportCreator = (payload, history) => ({
  type: UPDATE_REPORT,
  payload,
  history,
});

export const setMaReportErrorsCreator = (payload) => ({
  type: SET_MA_REPORT_ERRORS,
  payload,
});

export const downloadMaPeopleReportCreator = (payload) => ({
  type: DOWNLOAD_MA_PEOPLE_REPORT,
  payload,
});

export const loadMaReportsListCreator = () => ({
  type: LOAD_MA_REPORT_LIST,
});

export const setMaReportsListCreator = (payload) => ({
  type: SET_MA_REPORT_LIST,
  payload,
});

export const loadMaReportCreator = (payload) => ({
  type: LOAD_MA_REPORT,
  payload,
});

export const setMaReportCreator = (payload) => ({
  type: SET_MA_REPORT,
  payload,
});

export const setMaUpdateReportCreator = (payload) => ({
  type: SET_MA_UPDATE_REPORT,
  payload,
});

export const loadMaLastReportCreator = (payload) => ({
  type: LOAD_MA_LAST_REPORT,
  payload,
});

export const loadIpmaReportsListCreator = (country) => ({
  type: LOAD_IPMA_REPORT_LIST,
  country,
});

export const setIpmaReportsListCreator = (payload) => ({
  type: SET_IPMA_REPORT_LIST,
  payload,
});

export const toggleReportInvoiceCreator = (payload) => ({
  type: TOGGLE_REPORT_INVOICE,
  payload,
});

export const archiveReportCreator = (payload) => ({
  type: ARCHIVE_REPORT,
  payload,
});

export const setCourseBadgeListCreator = (payload) => ({
  type: SET_COURSE_BADGE_LIST,
  payload,
});

export const loadCourseBadgeListCreator = () => ({
  type: LOAD_COURSE_BADGE_LIST,
});

export const cisRedirectCreator = () => ({
  type: CIS_REDIRECT,
});

export const verifyReportCreator = (id, kamNotes, history) => ({
  type: VERIFY_REPORT,
  id,
  kamNotes,
  history,
});

export const loadMaPeopleYcCreator = () => ({
  type: LOAD_MA_PEOPLE_YC,
});

export const setMaPeopleYcCreator = (payload) => ({
  type: SET_MA_PEOPLE_YC,
  payload,
});

export const setMaPeopleYcSortCreator = (payload) => ({
  type: SET_MA_PEOPLE_YC_SORT,
  payload,
});

export const setMaPeopleYcPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_YC_PAGE,
  payload,
});

export const setMaPeopleYcPerPageCreator = (payload) => ({
  type: SET_MA_PEOPLE_YC_PER_PAGE,
  payload,
});

export const setOpenPendingBadgesCreator = (payload) => ({
  type: SET_OPEN_PENDING_BADGES,
  payload,
});

export const setOpenPendingCouponCreator = (payload) => ({
  type: SET_OPEN_PENDING_COUPON,
  payload,
});

export const loadUserPendingBadgesCreator = () => ({
  type: LOAD_USER_PENDING_BADGES,
});

export const setUserPendingBadgesCreator = (payload) => ({
  type: SET_USER_PENDING_BADGES,
  payload,
});

export const claimPendingAwardCreator = (payload) => ({
  type: CLAIM_PENDING_AWARD,
  payload,
});

export const rejectPendingAwardCreator = (payload) => ({
  type: REJECT_PENDING_AWARD,
  payload,
});

export const claimPendingEventBadgeCreator = (payload) => ({
  type: CLAIM_EVENT_PENDING_BADGE,
  payload,
});

export const rejectPendingEventBadgeCreator = (payload) => ({
  type: REJECT_EVENT_PENDING_BADGE,
  payload,
});

export const acceptCourseBadgeCreator = (payload) => ({
  type: CLAIM_COURSE_BADGE,
  payload,
});

export const rejectCourseBadgeCreator = (payload) => ({
  type: REJECT_COURSE_BADGE,
  payload,
});

export const suppressCourseBadgeCreator = (payload) => ({
  type: SUPPRESS_COURSE_BADGE,
  payload,
});

export const suppressCouponCreator = (payload) => ({
  type: SUPPRESS_COUPON,
  payload,
});

export const acceptAssessorBadgeCreator = (payload) => ({
  type: CLAIM_ASSESSOR_BADGE,
  payload,
});

export const rejectAssessorBadgeCreator = (payload) => ({
  type: REJECT_ASSESSOR_BADGE,
  payload,
});

export const acceptHonoraryFellowBadgeCreator = (payload) => ({
  type: CLAIM_HONORARY_FELLOW_BADGE,
  payload,
});

export const rejectHonoraryFellowBadgeCreator = (payload) => ({
  type: REJECT_HONORARY_FELLOW_BADGE,
  payload,
});

export const downloadCourseDiplomaCreator = (payload) => ({
  type: DOWNLOAD_COURSE_DIPLOMA,
  payload,
});

export const exportReportCreator = (payload) => ({
  type: EXPORT_REPORT,
  payload,
});

// young crew module

export const loadGlobalYcRolesCreator = () => ({
  type: LOAD_GLOBAL_YC_ROLES,
});

export const setGlobalYcRolesCreator = (payload) => ({
  type: SET_GLOBAL_YC_ROLES,
  payload,
});

export const setYcDashboardCreator = (payload) => ({
  type: SET_YC_DASHBOARD,
  payload,
});

export const loadYcDashboardCreator = () => ({
  type: LOAD_YC_DASHBOARD,
});

export const setYcProfileCreator = (payload) => ({
  type: SET_YC_PROFILE,
  payload,
});

export const loadYcProfileCreator = () => ({
  type: LOAD_YC_PROFILE,
});

export const updateYcProfileCreator = (payload) => ({
  type: UPDATE_YC_PROFILE,
  payload,
});

export const setUpdateYcProfileErrorsCreator = (payload) => ({
  type: SET_UPDATE_YC_PROFILE_ERRORS,
  payload,
});

export const setYcProfileUsersRoleCreator = (payload) => ({
  type: SET_YC_PROFILE_USERS_ROLE,
  payload,
});

export const setYcProfileUpcomingEventsCreator = (payload) => ({
  type: SET_YC_PROFILE_UPCOMING_EVENTS,
  payload,
});

export const loadYcProfileUpcomingEventsCreator = () => ({
  type: LOAD_YC_PROFILE_UPCOMING_EVENTS,
});

export const setYcPeopleBarCreator = (payload) => ({
  type: SET_YC_PEOPLE_BAR,
  payload,
});

export const loadYcPeopleBarCreator = () => ({
  type: LOAD_YC_PEOPLE_BAR,
});

export const setYcPeopleUsersCreator = (payload) => ({
  type: SET_YC_PEOPLE_USERS,
  payload,
});

export const loadYcPeopleUsersCreator = () => ({
  type: LOAD_YC_PEOPLE_USERS,
});

export const setYcPeopleUsersSortCreator = (payload) => ({
  type: SET_YC_PEOPLE_USERS_SORT,
  payload,
});
export const setYcPeopleUsersPageCreator = (payload) => ({
  type: SET_YC_PEOPLE_USERS_PAGE,
  payload,
});
export const setYcPeopleUsersPerPageCreator = (payload) => ({
  type: SET_YC_PEOPLE_USERS_PER_PAGE,
  payload,
});

export const setYcPeopleListCreator = (payload) => ({
  type: SET_YC_PEOPLE_LIST,
  payload,
});

export const loadYcPeopleListCreator = () => ({
  type: LOAD_YC_PEOPLE_LIST,
});

export const loadYcMembersListCreator = () => ({
  type: LOAD_YC_MEMBERS_LIST,
});

export const setYcPeopleListSortCreator = (payload) => ({
  type: SET_YC_PEOPLE_LIST_SORT,
  payload,
});
export const setYcPeopleListPageCreator = (payload) => ({
  type: SET_YC_PEOPLE_LIST_PAGE,
  payload,
});
export const setYcPeopleListPerPageCreator = (payload) => ({
  type: SET_YC_PEOPLE_LIST_PER_PAGE,
  payload,
});
export const setYcPeopleAddListUsersCreator = (payload) => ({
  type: SET_YC_PEOPLE_ADD_LIST_USERS,
  payload,
});

export const changeYcPeopleUsersRoleCreator = (payload) => ({
  type: CHANGE_YC_PEOPLE_USERS_ROLE,
  payload,
});

export const deleteYcPeopleUserCreator = (payload) => ({
  type: DELETE_YC_PEOPLE_USER,
  payload,
});

export const loadYcPeopleAddListUsersCreator = (payload) => ({
  type: LOAD_YC_PEOPLE_ADD_LIST_USERS,
  payload,
});

export const addYcPeopleUsersCreator = (payload, history) => ({
  type: ADD_YC_PEOPLE_USERS,
  payload,
  history,
});

export const acceptYcPeopleListUserCreator = (payload) => ({
  type: ACCEPT_YC_PEOPLE_LIST_USER,
  payload,
});

export const declineYcPeopleListUserCreator = (payload) => ({
  type: DECLINE_YC_PEOPLE_LIST_USER,
  payload,
});

export const removeYcPeopleListUserCreator = (payload) => ({
  type: REMOVE_YC_PEOPLE_LIST_USER,
  payload,
});

export const downloadYcPeopleReportCreator = (payload) => ({
  type: DOWNLOAD_YC_PEOPLE_REPORT,
  payload,
});

export const loadGlobalAvailableYCCreator = () => ({
  type: LOAD_GLOBAL_AVAILABLE_YC,
});

export const setGlobalAvailableYCCreator = (payload) => ({
  type: SET_GLOBAL_AVAILABLE_YC,
  payload,
});

export const loadYcEventsCreator = (payload) => ({
  type: LOAD_YC_EVENTS,
  payload,
});

export const setYcEventsCreator = (payload) => ({
  type: SET_YC_EVENTS,
  payload,
});

export const loadYcEventsBarCreator = (payload) => ({
  type: LOAD_YC_EVENTS_BAR,
  payload,
});

export const setYcEventsBarCreator = (payload) => ({
  type: SET_YC_EVENTS_BAR,
  payload,
});

export const setYcEventsPageCreator = (payload) => ({
  type: SET_YC_EVENTS_PAGE,
  payload,
});

export const setYcEventsPerPageCreator = (payload) => ({
  type: SET_YC_EVENTS_PER_PAGE,
  payload,
});

export const setYcEventsStatusCreator = (payload) => ({
  type: SET_YC_EVENTS_STATUS,
  payload,
});

export const cancelYcEventCreator = (payload) => ({
  type: CANCEL_YC_EVENT,
  payload,
});

export const duplicateYcEventCreator = (payload) => ({
  type: DUPLICATE_YC_EVENT,
  payload,
});

export const reopenYcEventCreator = (payload) => ({
  type: REOPEN_YC_EVENT,
  payload,
});

export const createYcEventCreator = (payload, history, setErrors) => ({
  type: CREATE_YC_EVENT,
  payload,
  history,
  setErrors,
});

export const loadYcEventCreator = (payload, setEvent) => ({
  type: LOAD_YC_EVENT,
  payload,
  setEvent,
});

export const editYcEventCreator = (payload, id, history, setErrors) => ({
  type: EDIT_YC_EVENT,
  payload,
  id,
  history,
  setErrors,
});

export const setMaPeopleListFiltersCreator = (payload) => ({
  type: SET_MA_PEOPLE_LIST_FILTERS,
  payload,
});
export const loadMaPeopleListFiltersCreator = () => ({
  type: LOAD_MA_PEOPLE_LIST_FILTERS,
});

export const setMaPeopleMembersFiltersCreator = (payload) => ({
  type: SET_MA_PEOPLE_MEMBERS_FILTERS,
  payload,
});
export const loadMaPeopleMembersFiltersCreator = () => ({
  type: LOAD_MA_PEOPLE_MEMBERS_FILTERS,
});

export const setMaPeopleYcFiltersCreator = (payload) => ({
  type: SET_MA_PEOPLE_YC_FILTERS,
  payload,
});
export const loadMaPeopleYcFiltersCreator = () => ({
  type: LOAD_MA_PEOPLE_YC_FILTERS,
});

export const setMaPeopleUsersFiltersCreator = (payload) => ({
  type: SET_MA_PEOPLE_USERS_FILTERS,
  payload,
});
export const loadMaPeopleUsersFiltersCreator = () => ({
  type: LOAD_MA_PEOPLE_USERS_FILTERS,
});

export const setIpmaReportsFiltersCreator = (payload) => ({
  type: SET_IPMA_REPORTS_FILTERS,
  payload,
});
export const loadIpmaReportsFiltersCreator = () => ({
  type: LOAD_IPMA_REPORTS_FILTERS,
});

export const createIpmaProjectCreator = (payload, history, setErrors) => ({
  type: CREATE_IPMA_PROJECT,
  payload,
  history,
  setErrors,
});

export const loadIpmaProjectsCreator = (payload) => ({
  type: LOAD_IPMA_PROJECTS,
  payload,
});

export const setIpmaProjectsCreator = (payload) => ({
  type: SET_IPMA_PROJECTS,
  payload,
});

export const loadIpmaProjectsBarCreator = (payload) => ({
  type: LOAD_IPMA_PROJECTS_BAR,
  payload,
});

export const setIpmaProjectsBarCreator = (payload) => ({
  type: SET_IPMA_PROJECTS_BAR,
  payload,
});

export const setIpmaProjectsPageCreator = (payload) => ({
  type: SET_IPMA_PROJECTS_PAGE,
  payload,
});

export const setIpmaProjectsPerPageCreator = (payload) => ({
  type: SET_IPMA_PROJECTS_PER_PAGE,
  payload,
});

export const setIpmaProjectsStatusCreator = (payload) => ({
  type: SET_IPMA_PROJECTS_STATUS,
  payload,
});

export const loadIpmaProjectCreator = (payload, setProject) => ({
  type: LOAD_IPMA_PROJECT,
  payload,
  setProject,
});

export const setIpmaProjectCreator = (payload) => ({
  type: SET_IPMA_PROJECT,
  payload,
});

export const editIpmaProjectCreator = (payload, id, history, setErrors) => ({
  type: EDIT_IPMA_PROJECT,
  payload,
  id,
  history,
  setErrors,
});

export const createIpmaProjectRoleCreator = (payload, history, projectId, setErrors) => ({
  type: CREATE_IPMA_PROJECT_ROLE,
  payload,
  history,
  projectId,
  setErrors,
});

export const editIpmaProjectRoleCreator = (payload, id, history, projectId, setErrors) => ({
  type: EDIT_IPMA_PROJECT_ROLE,
  payload,
  id,
  history,
  projectId,
  setErrors,
});

export const loadIpmaProjectRolesCreator = (payload, id) => ({
  type: LOAD_IPMA_PROJECT_ROLES,
  payload,
  id,
});

export const setIpmaProjectRolesCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLES,
  payload,
});

export const loadIpmaProjectRolesBarCreator = (payload, id) => ({
  type: LOAD_IPMA_PROJECT_ROLES_BAR,
  payload,
  id,
});

export const setIpmaProjectRolesBarCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLES_BAR,
  payload,
});

export const setIpmaProjectRolesPageCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLES_PAGE,
  payload,
});

export const setIpmaProjectRolesPerPageCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLES_PER_PAGE,
  payload,
});

export const setIpmaProjectRolesStatusCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLES_STATUS,
  payload,
});

export const loadIpmaProjectRoleCreator = (payload, setProjectRole) => ({
  type: LOAD_IPMA_PROJECT_ROLE,
  payload,
  setProjectRole,
});

export const setIpmaProjectRoleCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLE,
  payload,
});

export const createIpmaProjectRoleApplicationCreator = (payload, closeModal, setErrors) => ({
  type: CREATE_IPMA_PROJECT_ROLE_APPLICATION,
  payload,
  closeModal,
  setErrors,
});

export const loadIpmaProjectRoleApplicationCreator = (payload) => ({
  type: LOAD_IPMA_PROJECT_ROLE_APPLICATION,
  payload,
});

export const setIpmaProjectRoleApplicationCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLE_APPLICATION,
  payload,
});

export const loadIpmaProjectRoleApplicationsCreator = (payload, id) => ({
  type: LOAD_IPMA_PROJECT_ROLE_APPLICATIONS,
  payload,
  id,
});

export const setIpmaProjectRoleApplicationsCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLE_APPLICATIONS,
  payload,
});

export const loadIpmaProjectRoleApplicationBarCreator = (payload, id) => ({
  type: LOAD_IPMA_PROJECT_ROLE_APPLICATION_BAR,
  payload,
  id,
});

export const setIpmaProjectRoleApplicationBarCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLE_APPLICATION_BAR,
  payload,
});

export const setIpmaProjectRoleApplicationPageCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLE_APPLICATION_PAGE,
  payload,
});

export const setIpmaProjectRoleApplicationPerPageCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLE_APPLICATION_PER_PAGE,
  payload,
});

export const setIpmaProjectRoleApplicationStatusCreator = (payload) => ({
  type: SET_IPMA_PROJECT_ROLE_APPLICATION_STATUS,
  payload,
});

export const acceptIpmaProjectRoleApplicationCreator = (payload, roleId) => ({
  type: ACCEPT_IPMA_PROJECT_ROLE_APPLICATION,
  payload,
  roleId,
});

export const declineIpmaProjectRoleApplicationCreator = (payload, roleId) => ({
  type: DECLINE_IPMA_PROJECT_ROLE_APPLICATION,
  payload,
  roleId,
});

export const putOnHoldIpmaProjectRoleApplicationCreator = (payload, roleId) => ({
  type: PUT_ON_HOLD_IPMA_PROJECT_ROLE_APPLICATION,
  payload,
  roleId,
});

export const downloadIpmaApplicationsCreator = (roleId, roleStatus) => ({
  type: DOWNLOAD_IPMA_APPLICATIONS,
  roleId,
  roleStatus,
});

export const createProjectCreator = (payload, history, setErrors) => ({
  type: CREATE_PROJECT,
  payload,
  history,
  setErrors,
});

export const loadYcProjectsCreator = (payload) => ({
  type: LOAD_YC_PROJECTS,
  payload,
});

export const setYcProjectsCreator = (payload) => ({
  type: SET_YC_PROJECTS,
  payload,
});

export const loadYcProjectsBarCreator = (payload) => ({
  type: LOAD_YC_PROJECTS_BAR,
  payload,
});

export const setYcProjectsBarCreator = (payload) => ({
  type: SET_YC_PROJECTS_BAR,
  payload,
});

export const setYcProjectsPageCreator = (payload) => ({
  type: SET_YC_PROJECTS_PAGE,
  payload,
});

export const setYcProjectsPerPageCreator = (payload) => ({
  type: SET_YC_PROJECTS_PER_PAGE,
  payload,
});

export const setYcProjectsStatusCreator = (payload) => ({
  type: SET_YC_PROJECTS_STATUS,
  payload,
});

export const loadYcProjectCreator = (payload, setProject) => ({
  type: LOAD_YC_PROJECT,
  payload,
  setProject,
});

export const setYcProjectCreator = (payload) => ({
  type: SET_YC_PROJECT,
  payload,
});

export const editYcProjectCreator = (payload, id, history, setErrors) => ({
  type: EDIT_YC_PROJECT,
  payload,
  id,
  history,
  setErrors,
});

export const loadGlobalProjectCareerLevelCreator = () => ({
  type: LOAD_GLOBAL_PROJECT_CAREERLEVEL,
});

export const loadGlobalProjectSkillsCreator = () => ({
  type: LOAD_GLOBAL_PROJECT_SKILLS,
});

export const loadGlobalProjectRolesCreator = () => ({
  type: LOAD_GLOBAL_PROJECT_ROLES,
});

export const setGlobalProjectCareerLevelCreator = (payload) => ({
  type: SET_GLOBAL_PROJECT_CAREERLEVEL,
  payload,
});

export const setGlobalProjectSkillsCreator = (payload) => ({
  type: SET_GLOBAL_PROJECT_SKILLS,
  payload,
});

export const setGlobalProjectRolesCreator = (payload) => ({
  type: SET_GLOBAL_PROJECT_ROLES,
  payload,
});

export const createProjectRoleCreator = (payload, history, projectId, setErrors) => ({
  type: CREATE_PROJECT_ROLE,
  payload,
  history,
  projectId,
  setErrors,
});

export const editYcProjectRoleCreator = (payload, id, history, projectId, setErrors) => ({
  type: EDIT_YC_PROJECT_ROLE,
  payload,
  id,
  history,
  projectId,
  setErrors,
});

export const loadYcProjectRolesCreator = (payload, id) => ({
  type: LOAD_YC_PROJECT_ROLES,
  payload,
  id,
});

export const setYcProjectRolesCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLES,
  payload,
});

export const loadYcProjectRolesBarCreator = (payload, id) => ({
  type: LOAD_YC_PROJECT_ROLES_BAR,
  payload,
  id,
});

export const setYcProjectRolesBarCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLES_BAR,
  payload,
});

export const setYcProjectRolesPageCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLES_PAGE,
  payload,
});

export const setYcProjectRolesPerPageCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLES_PER_PAGE,
  payload,
});

export const setYcProjectRolesStatusCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLES_STATUS,
  payload,
});

export const loadYcProjectRoleCreator = (payload, setProjectRole) => ({
  type: LOAD_YC_PROJECT_ROLE,
  payload,
  setProjectRole,
});

export const setYcProjectRoleCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLE,
  payload,
});

export const createProjectRoleApplicationCreator = (payload, closeModal, setErrors) => ({
  type: CREATE_PROJECT_ROLE_APPLICATION,
  payload,
  closeModal,
  setErrors,
});

export const loadYcProjectRoleApplicationCreator = (payload) => ({
  type: LOAD_YC_PROJECT_ROLE_APPLICATION,
  payload,
});

export const setYcProjectRoleApplicationCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLE_APPLICATION,
  payload,
});

export const loadYcProjectRoleApplicationsCreator = (payload, id) => ({
  type: LOAD_YC_PROJECT_ROLE_APPLICATIONS,
  payload,
  id,
});

export const setYcProjectRoleApplicationsCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLE_APPLICATIONS,
  payload,
});

export const loadYcProjectRoleApplicationBarCreator = (payload, id) => ({
  type: LOAD_YC_PROJECT_ROLE_APPLICATION_BAR,
  payload,
  id,
});

export const setYcProjectRoleApplicationBarCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLE_APPLICATION_BAR,
  payload,
});

export const setYcProjectRoleApplicationPageCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLE_APPLICATION_PAGE,
  payload,
});

export const setYcProjectRoleApplicationPerPageCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLE_APPLICATION_PER_PAGE,
  payload,
});

export const setYcProjectRoleApplicationStatusCreator = (payload) => ({
  type: SET_YC_PROJECT_ROLE_APPLICATION_STATUS,
  payload,
});

export const acceptYcProjectRoleApplicationCreator = (payload, roleId) => ({
  type: ACCEPT_YC_PROJECT_ROLE_APPLICATION,
  payload,
  roleId,
});

export const declineYcProjectRoleApplicationCreator = (payload, roleId) => ({
  type: DECLINE_YC_PROJECT_ROLE_APPLICATION,
  payload,
  roleId,
});

export const putOnHoldYcProjectRoleApplicationCreator = (payload, roleId) => ({
  type: PUT_ON_HOLD_YC_PROJECT_ROLE_APPLICATION,
  payload,
  roleId,
});

export const setYcPeopleUsersFiltersCreator = (payload) => ({
  type: SET_YC_PEOPLE_USERS_FILTERS,
  payload,
});

export const loadYcPeopleUsersFiltersCreator = () => ({
  type: LOAD_YC_PEOPLE_USERS_FILTERS,
});

export const setYcPeopleListFiltersCreator = (payload) => ({
  type: SET_YC_PEOPLE_LIST_FILTERS,
  payload,
});

export const loadYcPeopleListFiltersCreator = () => ({
  type: LOAD_YC_PEOPLE_LIST_FILTERS,
});

export const downloadYcApplicationsCreator = (roleId, roleStatus) => ({
  type: DOWNLOAD_YC_APPLICATIONS,
  roleId,
  roleStatus,
});

export const setGlobalAboutIpmaCreator = (payload) => ({
  type: SET_GLOBAL_ABOUT_IPMA,
  payload,
});

export const loadGlobalAboutIpmaCreator = () => ({
  type: LOAD_GLOBAL_ABOUT_IPMA,
});

export const setUserPendingCertificationsBadgesCreator = (payload) => ({
  type: SET_USER_PENDING_CERTIFICATIONS,
  payload,
});

export const loadUserPendingCertificatesCreator = () => ({
  type: LOAD_USER_PENDING_CERTIFICATIONS,
});

export const claimUserCertificateCreator = (payload) => ({
  type: CLAIM_USER_CERTIFICATE,
  payload,
});

export const removeIpmaProjectCreator = (id) => ({
  type: REMOVE_IPMA_PROJECT,
  id,
});

export const removeIpmaProjectRoleCreator = (id) => ({
  type: REMOVE_IPMA_PROJECT_ROLE,
  id,
});

export const removeYcProjectCreator = (id) => ({
  type: REMOVE_YC_PROJECT,
  id,
});

export const removeYcProjectRoleCreator = (id) => ({
  type: REMOVE_YC_PROJECT_ROLE,
  id,
});

export const loadUserCpdListCreator = () => ({
  type: LOAD_USER_CPD_LIST,
});

export const setUserCpdListCreator = (payload) => ({
  type: SET_USER_CPD_LIST,
  payload,
});

export const createUserApplyForCpdCreator = (payload, closeModal, setErrors) => ({
  type: USER_APPLY_FOR_CPD,
  payload,
  closeModal,
  setErrors,
});

export const loadMyLocalLibraryCreator = () => ({
  type: LOAD_MY_LOCAL_LIBRARY,
});

export const setLocalLibraryCreator = (payload) => ({
  type: SET_MY_LOCAL_LIBRARY,
  payload,
});

export const loadCpdCategoriesCreator = () => ({
  type: LOAD_CPD_CATEGORIES,
});

export const setCpdCategoriesCreator = (payload) => ({
  type: SET_CPD_CATEGORIES,
  payload,
});

export const loadMaCpdRequestsCreator = (payload) => ({
  type: LOAD_MA_CPD_REQUESTS,
  payload,
});

export const setMaCpdRequestsCreator = (payload) => ({
  type: SET_MA_CPD_REQUESTS,
  payload,
});

export const loadMaCpdRequestsBarCreator = (payload) => ({
  type: LOAD_MA_CPD_REQUESTS_BAR,
  payload,
});

export const setMaCpdRequestsBarCreator = (payload) => ({
  type: SET_MA_CPD_REQUESTS_BAR,
  payload,
});

export const setMaCpdRequestsPageCreator = (payload) => ({
  type: SET_MA_CPD_REQUESTS_PAGE,
  payload,
});

export const setMaCpdRequestsPerPageCreator = (payload) => ({
  type: SET_MA_CPD_REQUESTS_PER_PAGE,
  payload,
});

export const setMaCpdRequestsStatusCreator = (payload) => ({
  type: SET_MA_CPD_REQUESTS_STATUS,
  payload,
});

export const acceptCpdRequestCreator = (payload) => ({
  type: ACCEPT_CPD_REQUEST,
  payload,
});

export const rejectCpdRequestCreator = (payload) => ({
  type: REJECT_CPD_REQUEST,
  payload,
});

export const setCpdRolesCreator = (payload) => ({
  type: SET_CPD_ROLES,
  payload,
});

export const loadCpdRolesCreator = () => ({
  type: LOAD_CPD_ROLES,
});

export const loadGlobalAvailableCBCreator = (payload) => ({
  type: LOAD_GLOBAL_AVAILABLE_CB,
  payload,
});

export const setGlobalAvailableCBCreator = (payload) => ({
  type: SET_GLOBAL_AVAILABLE_CB,
  payload,
});

export const sendUserCpdForVerificationCreator = (payload, closeModal, setErrors) => ({
  type: SEND_USER_CPD_FOR_VERIFICATION,
  payload,
  closeModal,
  setErrors,
});

export const loadMaCpdBatchesCreator = (payload) => ({
  type: LOAD_MA_CPD_BATCH_REQUESTS,
  payload,
});

export const setMaCpdBatchesCreator = (payload) => ({
  type: SET_MA_CPD_BATCH_REQUESTS,
  payload,
});

export const setMaCpdBatchesPageCreator = (payload) => ({
  type: SET_MA_CPD_BATCH_REQUESTS_PAGE,
  payload,
});

export const setMaCpdBatchesPerPageCreator = (payload) => ({
  type: SET_MA_CPD_BATCH_REQUESTS_PER_PAGE,
  payload,
});

export const loadMaCpdBatchesListCreator = (payload) => ({
  type: LOAD_MA_CPD_BATCH_REQUESTS_LIST,
  payload,
});

export const setMaCpdBatchesListCreator = (payload) => ({
  type: SET_MA_CPD_BATCH_REQUESTS_LIST,
  payload,
});

export const saveUserBatchCpdsCreator = (payload) => ({
  type: SAVE_USER_BATCH_CPDS,
  payload,
});

export const exportBatchCsvCreator = (payload) => ({
  type: EXPORT_USER_BATCH_CPD,
  payload,
});

export const exportUserCpdCsvCreator = (payload) => ({
  type: EXPORT_USER_CPD_CSV,
  payload,
});

export const setAvaiableOrganisationsCreator = (payload) => ({
  type: SET_AVA_ORGANISATIONS,
  payload,
});

export const loadAvaiableOrganisationsCreator = () => ({
  type: LOAD_AVA_ORGANISATIONS,
});

export const setCurrentOrganisationsCreator = (payload) => ({
  type: SET_CURRENT_ORGANISATIONS,
  payload,
});

export const loadCurrentOrganisationsCreator = () => ({
  type: LOAD_CURRENT_ORGANISATIONS,
});
