import addCicrle from './icons/add_circle.svg';
import alertCircle from './icons/alert_circle.svg';
import arrowUp from './icons/arrow_up.svg';
import arrowDown from './icons/arrow_down.svg';
import arrowLeft from './icons/arrow_left.svg';
import arrowRight from './icons/arrow_right.svg';
import arrowRightCircle from './icons/arrow_right_circle.svg';
import bellSlashed from './icons/bell-slashed.svg';
import birthdate from './icons/birthdate.svg';
import calendar from './icons/calendar.svg';
import check from './icons/check.svg';
import checkCircle from './icons/check_circle.svg';
import checkRectangle from './icons/check_rectangle.svg';
import checkThin from './icons/check_thin.svg';
import close from './icons/close.svg';
import closeCircle from './icons/close_circle.svg';
import download from './icons/download.svg';
import duplicate from './icons/duplicate.svg';
import edit from './icons/edit.svg';
import eye from './icons/eye.svg';
import eyeCrossed from './icons/eye_crossed.svg';
import facebook from './icons/facebook.svg';
import file from './icons/file.svg';
import flag from './icons/flag.svg';
import hamburger from './icons/hamburger.svg';
import linkedin from './icons/linkedin.svg';
import lock from './icons/lock.svg';
import lockClose from './icons/lock_close.svg';
import lockOpen from './icons/lock_open.svg';
import logout from './icons/logout.svg';
import ma from './icons/ma.svg';
import mail from './icons/mail.svg';
import marker from './icons/marker.svg';
import notification from './icons/notification.svg';
import notification2 from './icons/notification2.svg';
import logInNew from './icons/log-in-new.svg';
import registerNew from './icons/register-new.svg';
import searchNew from './icons/search_new.svg';
import organisation from './icons/organisation.svg';
import phone from './icons/phone.svg';
import question from './icons/question.svg';
import search from './icons/search.svg';
import sort from './icons/sort.svg';
import twitter from './icons/twitter.svg';
import user from './icons/user.svg';
import userCheck from './icons/user_check.svg';
import userSecond from './icons/user_second.svg';
import users from './icons/users.svg';
import usersSecond from './icons/users_second.svg';
import google from './icons/google.svg';
import microsoft from './icons/microsoft.svg';
import windows from './icons/windows.svg';
import googleColor from './icons/google_color.svg';
import facebookSquare from './icons/facebook-square.svg';
import ipma from './icons/ipma.svg';
import tiktok from './icons/tiktok.svg';
import award from './icons/award.svg';
import clock from './icons/clock.svg';
import upload from './icons/upload.svg';
import fileUpload from './icons/file-upload-light.svg';
import link from './icons/link.svg';
import yc from './icons/seedling.svg';
import list from './icons/list.svg';
import steps from './icons/steps.svg';
import badge from './icons/badge.svg';
import arrowRightAlt from './icons/arrow_right_alt.svg';
import youtube from './icons/youtube.svg';
import instagram from './icons/instagram.svg';
import ticket from './icons/ticket.svg';
import share from './icons/share-alt.svg';
import loginIcon from './icons/login-icon.svg';
import registerUser from './icons/register-user.svg';
import searchIcon from './icons/search-icon.svg';
import cart from './icons/cart-icon.png';
import userNew from './icons/user-new.svg';
import notificationNew from './icons/notification-new.svg';
import tiktokWhite from './icons/tiktok-white.svg';
import signature from './icons/signature.svg';
import trophy from './icons/trophy-alt.svg';

export const iconList = {
  addCicrle,
  alertCircle,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowRightCircle,
  bellSlashed,
  birthdate,
  calendar,
  check,
  checkCircle,
  checkRectangle,
  checkThin,
  close,
  closeCircle,
  download,
  duplicate,
  edit,
  eye,
  eyeCrossed,
  facebook,
  file,
  flag,
  hamburger,
  linkedin,
  lock,
  lockClose,
  lockOpen,
  logout,
  ma,
  mail,
  marker,
  notification,
  notification2,
  logInNew,
  registerNew,
  searchNew,
  organisation,
  phone,
  question,
  search,
  sort,
  twitter,
  user,
  userCheck,
  userSecond,
  users,
  usersSecond,
  google,
  microsoft,
  googleColor,
  windows,
  facebookSquare,
  ipma,
  tiktok,
  award,
  clock,
  upload,
  fileUpload,
  link,
  yc,
  list,
  steps,
  badge,
  arrowRightAlt,
  youtube,
  instagram,
  ticket,
  share,
  loginIcon,
  registerUser,
  searchIcon,
  cart,
  userNew,
  notificationNew,
  tiktokWhite,
  signature,
  trophy,
};
