import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Brand from 'components/Navbar/Brand/Brand';
import { LoginButton, SearchNew, ColIcon, SearchMain } from 'components';
import Logged from 'components/Navbar/Desktop/Logged/Logged';
import List from 'components/Navbar/List/List';
import { globalPropTypes, isLoading } from 'helpers';
import { MyLibrary } from 'shared';
import { isLogin } from 'api';
import { connectAzureCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import myIpmaLogo from 'assets/images/logo.png';
import ipmaLogo from 'assets/images/ipma-logo.png';
import IpmaShop from '../IpmaShop/IpmaShop';
import { Wrapper, Top, Middle, Bottom, RowStyled, Container, Col, Row } from './Desktop.styled';

const Desktop = ({
  globalRoutes,
  userRoutes,
  appType,
  connectAzure,
  connectAzureLoading,
  openHelpCenter,
  openCisModal,
}) => {
  const { t } = useTranslation();
  const isLogged = isLogin() && appType !== 'guest';
  const menuOpen = new Array(6).fill(false);
  const loggedRef = useRef(null);
  const redirect = (url) => {
    window.open(url, '_blank').focus();
  };

  function hideModal(element) {
    const innerModalNumber = `modal${element}`;
    menuOpen[element] = false;
    document.getElementById(innerModalNumber).style.visibility = 'hidden';
    if (element === 5) {
      document.getElementById(innerModalNumber).style.height = '0px';
    }
  }

  function setMenuOpen(number, state) {
    menuOpen[number] = state;
    const modalNumber = `modal${number}`;
    document.getElementById(modalNumber).style.visibility = state ? 'visible' : 'hidden';
    if (number === 5) {
      if (state) {
        document.getElementById(modalNumber).style.height = '90px';
      } else {
        document.getElementById(modalNumber).style.height = '0px';
      }
    }
    menuOpen.forEach((value, element) => {
      if (element !== number) {
        hideModal(element);
      }
    });
  }

  useEffect(() => {
    const handleClick = (event) => {
      if (loggedRef.current && !loggedRef.current.contains(event.target)) {
        menuOpen.forEach((value, element) => {
          hideModal(element);
        });
      }
    };

    const handleScroll = () => {
      menuOpen.forEach((value, element) => {
        hideModal(element);
      });
    };

    menuOpen.forEach((value, element) => {
      if (element !== 5) {
        const column = `colIcon${element}`;
        const innerModalNumber = `modal${element}`;
        if (document.getElementById(innerModalNumber)) {
          document.getElementById(column).addEventListener('mouseover', function handleMouseOver() {
            document.getElementById(innerModalNumber).style.visibility = 'visible';
            menuOpen[element] = true;
            menuOpen.forEach((value2, element2) => {
              if (element2 !== element) {
                hideModal(element2);
              }
            });
          });
          document.addEventListener('click', function handleClickOutsideBox(event) {
            if (
              document.getElementById(innerModalNumber) &&
              !document.getElementById(innerModalNumber).contains(event.target)
            ) {
              document.getElementById(innerModalNumber).style.visibility = 'hidden';
            }
          });
        }
      } else {
        document.addEventListener('click', function handleClickOutsideBox(event) {
          if (
            document.getElementById('modal5') &&
            !document.getElementById('modal5').contains(event.target) &&
            document.getElementById('searchButton') &&
            !document.getElementById('searchButton').contains(event.target)
          ) {
            document.getElementById('modal5').style.visibility = 'hidden';
            document.getElementById('modal5').style.height = '0px';
            menuOpen[5] = false;
          }
        });
      }
    });

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper>
      <Container>
        <Top isLogged={isLogged}>
          <div>
            <Brand as={Link} to="/" large="true" logo={myIpmaLogo} />
          </div>
          <RowStyled>
            <ColIcon
              id="colIcon0"
              onClick={() => setMenuOpen(0, !menuOpen[0])}
              open={menuOpen[0]}
              routes={userRoutes.recognisedLinks}
              modalId="modal0"
              icon="arrowDown"
              style={{
                padding: '0px',
                fontWeight: 500,
                fontFamily: 'Montserrat',
                flexShrink: 0,
              }}
            >
              {t('navigation.ipmaWorld.getRecognised')}
            </ColIcon>
            <ColIcon
              id="colIcon1"
              onClick={() => setMenuOpen(1, !menuOpen[1])}
              open={menuOpen[1]}
              routes={userRoutes.engagedLinks}
              modalId="modal1"
              icon="arrowDown"
              style={{
                padding: '0px',
                fontWeight: 500,
                fontFamily: 'Montserrat',
                flexShrink: 0,
              }}
            >
              {t('navigation.ipmaWorld.getEngaged')}
            </ColIcon>
            <ColIcon
              id="colIcon2"
              onClick={() => setMenuOpen(2, !menuOpen[2])}
              open={menuOpen[2]}
              routes={userRoutes.improveLinks}
              modalId="modal2"
              icon="arrowDown"
              style={{
                padding: '0px',
                fontWeight: 500,
                fontFamily: 'Montserrat',
                flexShrink: 0,
              }}
            >
              {t('navigation.ipmaWorld.improveWithIpma')}
            </ColIcon>
            <ColIcon
              id="colIcon3"
              onClick={() => setMenuOpen(3, !menuOpen[3])}
              open={menuOpen[3]}
              routes={userRoutes.involvedLinks}
              modalId="modal3"
              icon="arrowDown"
              style={{
                padding: '0px',
                fontWeight: 500,
                fontFamily: 'Montserrat',
                flexShrink: 0,
              }}
            >
              {t('navigation.ipmaWorld.getInvolved')}
            </ColIcon>
            <ColIcon
              id="colIcon4"
              onClick={() => setMenuOpen(4, !menuOpen[4])}
              open={menuOpen[4]}
              routes={userRoutes.aboutLinks}
              modalId="modal4"
              icon="arrowDown"
              style={{
                padding: '0px',
                fontWeight: 500,
                fontFamily: 'Montserrat',
                flexShrink: 0,
              }}
            >
              {t('navigation.ipmaWorld.aboutIpma')}
            </ColIcon>
            <Col
              onClick={() => redirect('https://ipma.world/news/')}
              style={{
                padding: '0px',
                cursor: 'pointer',
                color: 'black',
                fontWeight: 500,
                textAlign: 'center',
                fontFamily: 'Montserrat',
              }}
            >
              {t('navigation.ipmaWorld.news')}
            </Col>
            <Col
              onClick={() => redirect('https://ipma.world/blog/')}
              style={{
                padding: '0px',
                cursor: 'pointer',
                color: 'black',
                fontWeight: 500,
                textAlign: 'center',
                fontFamily: 'Montserrat',
              }}
            >
              {t('navigation.ipmaWorld.blog')}
            </Col>
            <Brand onClick={() => redirect('https://ipma.world/')} logo={ipmaLogo} />
            <Col
              id="searchButton"
              style={{
                padding: '0px',
                fontWeight: 500,
                fontFamily: 'Montserrat',
              }}
            >
              <SearchNew onClick={() => setMenuOpen(5, !menuOpen[5])} small />
            </Col>
            {appType !== 'guest' ? (
              <Col as={Link} to="/ipma-shop" target="_blank">
                <IpmaShop />
              </Col>
            ) : (
              <Col onClick={() => redirect('https://shop.ipma.world')}>
                <IpmaShop />
              </Col>
            )}
          </RowStyled>
          {isLogged ? (
            <Logged userRoutes={userRoutes} />
          ) : (
            <Row>
              <LoginButton
                style={{
                  background: 'transparent',
                  color: 'black',
                  border: 'none',
                  fontWeight: 500,
                  fontFamily: 'Montserrat',
                  textTransform: 'none',
                }}
                as={Link}
                to="/auth/login"
                icon="loginIcon"
                rounded="true"
                tooltipContent="Login"
              />
              <LoginButton
                as={Link}
                to="/auth/register"
                icon="registerUser"
                rounded="true"
                outline="true"
                tooltipContent="Register"
                style={{
                  background: 'transparent',
                  color: 'black',
                  border: 'none',
                  fontWeight: 500,
                  fontFamily: 'Montserrat',
                  textTransform: 'none',
                }}
              />
            </Row>
          )}
        </Top>
      </Container>
      <SearchMain style={{ visibility: 'hidden', height: '0px' }} id="modal5" />
      <Container>
        <Middle>
          <List items={globalRoutes} horizontal />
        </Middle>
      </Container>
      {isLogged && userRoutes.links.length ? (
        <div style={{ backgroundColor: '#7b7a85' }}>
          <Container className="h-full">
            <Bottom>
              <List
                items={userRoutes.links}
                horizontal
                light
                smallfont
                extra={(Item, ItemLink) => (
                  <>
                    {appType === 'user' ? (
                      <>
                        {/* <Item horizontal="true">
                          <MyLibrary mobile={false} />
                        </Item> */}
                        <Item horizontal="true">
                          <ItemLink
                            as="button"
                            type="button"
                            horizontal="true"
                            light
                            smallfont
                            onClick={connectAzure}
                            disabled={connectAzureLoading}
                          >
                            {t('global.myWorkspacesButton')}
                          </ItemLink>
                        </Item>
                      </>
                    ) : null}
                    {appType === 'ma' || appType === 'ipma' ? (
                      <Item horizontal="true">
                        <ItemLink
                          as="button"
                          type="button"
                          horizontal="true"
                          light
                          smallfont
                          onClick={() => openCisModal('ma')}
                        >
                          {t('global.cis')}
                        </ItemLink>
                      </Item>
                    ) : null}
                    {appType === 'ma' || appType === 'ipma' || appType === 'yc' ? (
                      <Item horizontal="true">
                        <ItemLink
                          as="button"
                          type="button"
                          horizontal="true"
                          light
                          smallfont
                          onClick={() => openHelpCenter('ma')}
                        >
                          {t('global.helpCenterButton')}
                        </ItemLink>
                      </Item>
                    ) : null}
                  </>
                )}
              />
            </Bottom>
          </Container>
        </div>
      ) : null}
    </Wrapper>
  );
};

Desktop.propTypes = {
  globalRoutes: globalPropTypes.GlobalRoutesPropsTypes.isRequired,
  userRoutes: globalPropTypes.UserRoutesPropsTypes.isRequired,
  appType: PropTypes.string.isRequired,
  connectAzure: PropTypes.func.isRequired,
  connectAzureLoading: PropTypes.bool.isRequired,
  openHelpCenter: PropTypes.func.isRequired,
  openCisModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ loading }) => ({
  connectAzureLoading: isLoading(loading, 'CONNECT_AZURE'),
});

const mapDispatchToProps = (dispatch) => ({
  connectAzure: () => dispatch(connectAzureCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
