import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadMyLocalLibraryCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import { Container, Header, PageContent } from 'components';
import BookCards from '../../../components/BookCard/BookCards';
import MyLibrary from '../../../shared/MyLibrary/MyLibrary';
import ReactPaginate from 'react-paginate';
import { Pagination, PaginationSelect, PaginationText } from './MyLocalLibrary.styled';

import './pagination.css';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.myLibrary',
    url: '/user/my-books',
  },
];

const MyLocalLibrary = ({ loadLibrary, library, memberAssociationPeople }) => {
  const { t } = useTranslation();
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = library.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(library.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % library.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    loadLibrary();
  }, [loadLibrary]);

  if (memberAssociationPeople !== 'Yes') {
    return (
      <div
        style={{
          minHeight: '100px',
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span>{t('global.notAMemeber')}</span>
      </div>
    );
  }

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.user.myLibrary.title')} />
      <PageContent>
        <MyLibrary screen />
        <Container>
          <Pagination>
            <PaginationText>{t('global.perPage')}</PaginationText>
            <PaginationSelect
              defaultValue={12}
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value="12">12</option>
              <option value="24">24</option>
              <option value="36">36</option>
              <option value="48">48</option>
            </PaginationSelect>
          </Pagination>
          <BookCards library={currentItems} />
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="pagination-active"
          />
        </Container>
      </PageContent>
    </>
  );
};

MyLocalLibrary.propTypes = {
  loadLibrary: PropTypes.func.isRequired,
  library: PropTypes.array.isRequired,
  memberAssociationPeople: PropTypes.string,
};

MyLocalLibrary.defaultProps = {
  library: [],
  memberAssociationPeople: '',
};

const mapStateToProps = ({ localLibrary, user }) => {
  return {
    library: localLibrary.libraryList,
    memberAssociationPeople: user.memberAssociationPeople,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadLibrary: () => dispatch(loadMyLocalLibraryCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLocalLibrary);
