import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isLogin } from 'api';
import { Margin } from 'styled-components-spacing';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import {
  validateRegistrationUserDataCreator,
  loadGlobalCountriesCreator,
  loginSocialCreator,
} from 'store/actionsCreators';
import PropTypes from 'prop-types';

import {
  Button,
  Checkbox,
  Col,
  Datepicker,
  Field,
  Heading,
  Radio,
  Row,
  Select,
  Stepper,
  Password,
} from 'components';
import { StepperWrapper, Separator, DisabledRow } from 'containers/auth/Register/Register.styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isNull } from 'lodash';
import {
  GoogleButton,
  MicrosoftButton,
  FacebookButton,
  LinkedinButton,
  TwitterButton
} from 'containers/auth/Login/Login.styled';

const Spacer = ({ children }) => <Margin top={{ xs: 12 }}>{children}</Margin>;

Spacer.propTypes = {
  children: PropTypes.elementType.isRequired,
};

function UserStep({
  user,
  countries,
  errors,
  validateRegistrationUserData,
  loadGlobalCountries,
  history,
  loginSocial,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (isLogin()) {
      history.replace('/');
    }
    if (!countries.length) loadGlobalCountries();
  }, []);
  const [tempUser, setTempUser] = useState({ ...user });

  useEffect(() => {
    setTempUser({ ...user });
  }, [user]);

  const handleChangeNative = (name) => (e) => {
    setTempUser({
      ...tempUser,
      [name]: e.target.value,
    });
  };

  const handleChangeCustom = (name) => (value) => {
    setTempUser({
      ...tempUser,
      [name]: value,
    });
  };

  const handleChangeMaNo = () => {
    setTempUser({
      ...tempUser,
      ma: 'no',
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateRegistrationUserData(tempUser, history);
  };

  return (
    <div>
      <Row halign="justify" valign="center">
        <Col size={{ xs: 3 / 4 }}>
          <Heading as="h1" size="h2">
            {t('auth.register')}
          </Heading>
        </Col>
        <StepperWrapper>
          <Stepper
            activeIndex={0}
            steps={[
              { label: 1, go: () => history.replace('/auth/register') },
              { label: 2, go: () => history.replace('/auth/register/extendedRegistrationStep') },
              { label: 3 },
            ]}
          />
        </StepperWrapper>
      </Row>
      <form onSubmit={onSubmit}>
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field id="first-name" icon="userSecond" error={errors.firstName}>
                <input
                  id="first-name"
                  placeholder={`${t('forms.firstNamePlaceholder')} *`}
                  name="firstName"
                  value={tempUser.firstName}
                  onChange={handleChangeNative('firstName')}
                />
              </Field>
            </Spacer>
          </Col>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field id="last-name" icon="userSecond" error={errors.lastName}>
                <input
                  id="last-name"
                  placeholder={`${t('forms.lastNamePlaceholder')} *`}
                  name="lastName"
                  value={tempUser.lastName}
                  onChange={handleChangeNative('lastName')}
                />
              </Field>
            </Spacer>
          </Col>
        </Row>
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field id="email" icon="mail" error={errors.email}>
                <input
                  id="email"
                  placeholder={`${t('forms.emailPlaceholder')} *`}
                  name="email"
                  value={tempUser.email}
                  onChange={handleChangeNative('email')}
                />
              </Field>
            </Spacer>
          </Col>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field id="birthdate" icon="birthdate" error={errors.dob}>
                <Datepicker
                  name="dob"
                  showYearDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  onChange={handleChangeCustom('dob')}
                  defaultValue={new Date()}
                  selected={tempUser.dob}
                  placeholderText={`${t('forms.dobPlaceholder')} (${t('global.optional')})`}
                />
              </Field>
            </Spacer>
          </Col>
        </Row>
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field id="country" icon="flag" error={errors.country}>
                <Select
                  name="country"
                  width="200px"
                  value={tempUser.country}
                  onChange={handleChangeCustom('country')}
                  options={countries}
                  placeholder={`${t('forms.countryPlaceholder')} *`}
                />
              </Field>
            </Spacer>
          </Col>
        </Row>
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field
                mrAuto
                id="ma"
                label={t('page.auth.register.memberMAInThisCountry')}
                error={errors.ma}
              >
                <Row>
                  <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                    <Radio
                      onClick={() => handleChangeCustom('ma')('yes')}
                      selected={tempUser.ma === 'yes'}
                    >
                      {t('global.yes')}
                    </Radio>
                  </Col>
                  <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                    <Radio onClick={() => handleChangeMaNo()} selected={tempUser.ma === 'no'}>
                      {t('global.no')}
                    </Radio>
                  </Col>
                </Row>
              </Field>
            </Spacer>
          </Col>
        </Row>
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field
                mrAuto
                id="ma"
                label={t('page.auth.register.isYoungCrewMember')}
                error={errors.youngCrewMember}
              >
                <Row>
                  {[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ].map((el) => (
                    <Col key={`young-crew-${el.value}`} size={{ xs: 1 / 1, md: 1 / 2 }}>
                      <Radio
                        onClick={() => handleChangeCustom('youngCrewMember')(el.value)}
                        selected={tempUser.youngCrewMember === el.value}
                      >
                        {el.label}
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Field>
            </Spacer>
          </Col>
        </Row>
        <Separator />
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field id="password" icon="lock" error={errors.password}>
                <Password
                  id="password"
                  placeholder={`${t('forms.passwordPlaceholder')} *`}
                  name="password"
                  value={tempUser.password}
                  onChange={handleChangeNative('password')}
                />
              </Field>
            </Spacer>
          </Col>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field id="re-password" icon="lock">
                <Password
                  id="re-password"
                  placeholder={`${t('forms.rePasswordPlaceholder')} *`}
                  name="repassword"
                  value={tempUser.repassword}
                  onChange={handleChangeNative('repassword')}
                />
              </Field>
            </Spacer>
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer>
              <Field error={errors.terms}>
                <Checkbox name="terms" onChange={() => {}} selected={tempUser.terms}>
                  <p>
                    {t('page.auth.register.registerTerms')}
                    <Link to="/terms-of-service" style={{ marginLeft: '4px', fontWeight: '600' }}>
                      Terms of Service
                    </Link>
                    ,
                    <Link
                      to="/privacy-policy"
                      style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '600' }}
                    >
                      Privacy Policy
                    </Link>
                    and
                    <Link
                      to="/cookie-policy"
                      style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '600' }}
                    >
                      Cookie Policy
                    </Link>
                  </p>
                </Checkbox>
              </Field>
            </Spacer>
          </Col>
        </Row>
        <Row>
          <Col size={{ xs: 1 / 1, md: 4 / 12, lg: 3 / 12 }}>
            <Spacer>
              <Button primary>Next step</Button>
            </Spacer>
          </Col>

          <Col>
            <Margin top="16" as="p" bottom={{ xs: 12 }}>
              {t('auth.orRegisterWith')}
            </Margin>
            <Row>
              <Col size={{ lg: 1 / 6 }}>
                <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                  <GoogleButton onClick={() => loginSocial('google')}>
                    <ReactSVG src={iconList.googleColor} />
                    {t('auth.sGoogle')}
                  </GoogleButton>
                </Margin>
              </Col>

              <Col size={{ lg: 1 / 6 }}>
                <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                  <MicrosoftButton onClick={() => loginSocial('microsoft')}>
                    <ReactSVG src={iconList.windows} />
                    {t('auth.sMicrosoft')}
                  </MicrosoftButton>
                </Margin>
              </Col>

              <Col size={{ lg: 1 / 6 }}>
                <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                  <FacebookButton onClick={() => loginSocial('facebook')}>
                    <ReactSVG src={iconList.facebookSquare} />
                    {t('auth.sFacebook')}
                  </FacebookButton>
                </Margin>
              </Col>

              <Col size={{ lg: 1 / 6 }}>
                <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                  <LinkedinButton onClick={() => loginSocial('linkedin')}>
                    <ReactSVG src={iconList.linkedin} />
                    {t('auth.sLinkedin')}
                  </LinkedinButton>
                </Margin>
              </Col>

              <Col size={{ lg: 1 / 6 }}>
                <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                  <TwitterButton onClick={() => loginSocial('twitter')}>
                    <ReactSVG src={iconList.twitter} />
                    {t('auth.sTwitter')}
                  </TwitterButton>
                </Margin>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
  );
}

UserStep.propTypes = {
  user: PropTypes.shape({}).isRequired,
  countries: PropTypes.shape([]).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    dob: PropTypes.any,
    country: PropTypes.string,
    ma: PropTypes.bool,
    password: PropTypes.string,
    terms: PropTypes.bool,
    youngCrewMember: PropTypes.bool,
  }).isRequired,
  validateRegistrationUserData: PropTypes.func.isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  loginSocial: PropTypes.func.isRequired,
};

const mapStateToProps = ({ registration, globals }) => ({
  user: registration.user,
  countries: globals.countries,
  errors: registration.errors,
});

const mapDispatchToProps = (dispatch) => ({
  validateRegistrationUserData: (data, history) =>
    dispatch(validateRegistrationUserDataCreator(data, history)),
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  loginSocial: (payload) => dispatch(loginSocialCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserStep);
